import request from '@/utils/request'

export function getAuthAppList(data) {
  return request({
    url: '/bd-gms/auth/app/list',
    method: 'post',
    data
  })
}

export function addAuthApp(data) {
    return request({
      url: '/bd-gms/auth/app',
      method: 'post',
      data
    })
  }

  
export function deleteAuthApp(data) {
    return request({
        url: '/bd-gms/auth/app' + "?id=" + data.id,
        method: 'delete'
    })
}

export function updateAuthApp(data) {
    return request({
      url: '/bd-gms/auth/app',
      method: 'post',
      data
    })
}