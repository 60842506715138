import request from '@/utils/request'
 
export function addCompany(data) {
  return request({
    url: '/bd-gms/customer/company',
    method: 'post',
    data
  })
}

export function getCompanyInfo(data) {
  return request({
    url: '/bd-gms/customer/company/info',
    method: 'post',
    data
  })
}

export function getCompanyList(data) {
  return request({
    url: '/bd-gms/customer/company/list',
    method: 'post',
    data
  })
}

export function deleteCompany(data) {
    return request({
      url: '/bd-gms/customer/company' + "?companyId=" + data.companyId,
      method: 'delete'
    })
  }

  export function updatecompany(data) {
    return request({
      url: '/bd-gms/customer/company/info',
      method: 'post',
      data
    })
  }