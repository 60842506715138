import request from '@/utils/request'
 
export function getTaskInfo(data) {
  return request({
    url: '/bd-gms/flight/task/info?taskCode=' + data.taskCode + "&sn=" + data.sn,
    method: 'get'
  })
}

export function getTaskLineInfo(data) {
  return request({
    url: '/bd-gms/flight/task/taskline?taskLineId=' + data.taskLineId,
    method: 'get'
  })
}

export function getTaskFaultInfo(data) {
    return request({
      url: '/bd-gms/flight/task/fault/list?taskCode=' + data.taskCode,
      method: 'get'
    })
  }


export function getTaskTracksList(data) {
  return request({
    url: '/bd-gms/flight/task/tracks?taskCode=' + data.taskCode + '&sn=' + data.sn,
    method: 'get'
  })
}