import request from '@/utils/request'
 
export function auth(data) {
  return request({
    url: '/bd-gms/dingding/auth?authCode=' + data.authCode,
    method: 'get',
    data
  })
}


export function config(data) {
  return request({
    url: '/bd-gms/dingding/config',
    method: 'get',
    data
  })
}
