import { getStatisticsOverview, getStatisticsOverviewTable, getStatisticsFlightTimeCount, getStatisticsDataSizeCount, getStatisticsTaskCount, getStatisticsFlighttimeTopnTable, 
  getStatisticsDatasizeTopnTable, getStatisticsTaskTopnTable, getDeviceStatisticsDataSizeCount, getDeviceStatisticsFlightTimeCount, getDeviceStatisticsTaskCount, getDeviceOnPowerCount,
  getDeviceRuntimeCount } from '@/api/devicedata'
import { getToken, getUserInfo } from '@/utils/auth'
import { resetRouter } from '@/router'

const getDefaultState = () => {
  return {
    token: getToken(),
    userInfo: getUserInfo(),
    name: '',
    avatar: ''
  }
}

const state = getDefaultState()

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState())
  }
}

const actions = {
  // user login
  querystatisticsoverview({ commit }, pageParm) {
    const { companyId, timeBy, timeNo, lifecyclePhase  } = pageParm
    return new Promise((resolve, reject) => {
        getStatisticsOverview({ lifecyclePhase: lifecyclePhase, companyId: companyId, timeBy: timeBy, timeNo: timeNo }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  querystatisticsoverviewtable({ commit }, pageParm) {
    const { companyId, timeBy, timeNo, lifecyclePhase, order  } = pageParm
    return new Promise((resolve, reject) => {
        getStatisticsOverviewTable({ lifecyclePhase: lifecyclePhase, companyId: companyId, timeBy: timeBy, timeNo: timeNo, order: order }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  querystatisticstimecount({ commit }, pageParm) {
    const { companyId, timeBy, timeNo , lifecyclePhase } = pageParm
    return new Promise((resolve, reject) => {
        getStatisticsFlightTimeCount({ lifecyclePhase: lifecyclePhase, companyId: companyId, timeBy: timeBy, timeNo: timeNo }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },
 
  querystatisticsdatasizecount({ commit }, pageParm) {
    const { companyId, timeBy, timeNo, lifecyclePhase } = pageParm
    return new Promise((resolve, reject) => {
        getStatisticsDataSizeCount({ lifecyclePhase: lifecyclePhase, companyId: companyId, timeBy: timeBy, timeNo: timeNo }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  querystatisticstaskcount({ commit }, pageParam) {
    const { companyId, timeBy, timeNo, lifecyclePhase } = pageParam
    return new Promise((resolve, reject) => {
        getStatisticsTaskCount({ lifecyclePhase: lifecyclePhase, companyId: companyId, timeBy: timeBy, timeNo: timeNo }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  querystatisticstopnflighttime({ commit }, pageParm) {
    const { companyId, timeBy, timeNo, lifecyclePhase, order  } = pageParm
    return new Promise((resolve, reject) => {
        getStatisticsFlighttimeTopnTable({ lifecyclePhase: lifecyclePhase, companyId: companyId, timeBy: timeBy, timeNo: timeNo, order: order }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },
 
  querystatisticstopndatasize({ commit }, pageParm) {
    const { companyId, timeBy, timeNo, lifecyclePhase, order  } = pageParm
    return new Promise((resolve, reject) => {
        getStatisticsDatasizeTopnTable({ lifecyclePhase: lifecyclePhase, companyId: companyId, timeBy: timeBy, timeNo: timeNo, order: order }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },
 
  querystatisticstopntask({ commit }, pageParm) {
    const { companyId, timeBy, timeNo, lifecyclePhase, order  } = pageParm
    return new Promise((resolve, reject) => {
        getStatisticsTaskTopnTable({ lifecyclePhase: lifecyclePhase, companyId: companyId, timeBy: timeBy, timeNo: timeNo, order: order }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },


  querydevicestatisticstimecount({ commit }, pageParm) {
    const { companyId, timeBy, timeNo, sn, lifecyclePhase  } = pageParm
    return new Promise((resolve, reject) => {
        getDeviceStatisticsFlightTimeCount({ companyId: companyId, timeBy: timeBy, timeNo: timeNo, lifecyclePhase: lifecyclePhase, sn: sn }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },
 
  querydevicestatisticsdatasizecount({ commit }, pageParm) {
    const { companyId, timeBy, timeNo, sn, lifecyclePhase  } = pageParm
    return new Promise((resolve, reject) => {
        getDeviceStatisticsDataSizeCount({ companyId: companyId, timeBy: timeBy, timeNo: timeNo, lifecyclePhase: lifecyclePhase, sn: sn }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  querydevicestatisticstaskcount({ commit }, pageParam) {
    const { companyId, timeBy, timeNo, sn, lifecyclePhase  } = pageParam
    return new Promise((resolve, reject) => {
        getDeviceStatisticsTaskCount({ companyId: companyId, timeBy: timeBy, timeNo: timeNo, lifecyclePhase: lifecyclePhase, sn: sn }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  
  queryonpowerstatistics({ commit }, pageParam) {
    const { companyId, timeBy, timeNo, sn, lifecyclePhase, channelType  } = pageParam
    return new Promise((resolve, reject) => {
        getDeviceOnPowerCount({ companyId: companyId, timeBy: timeBy, timeNo: timeNo, lifecyclePhase: lifecyclePhase, sn: sn, channelType: channelType }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  queryruntimestatistics({ commit }, pageParam) {
    const { companyId, timeBy, timeNo, sn, lifecyclePhase, channelType  } = pageParam
    return new Promise((resolve, reject) => {
        getDeviceRuntimeCount({ companyId: companyId, timeBy: timeBy, timeNo: timeNo, lifecyclePhase: lifecyclePhase, sn: sn, channelType: channelType }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}