import { createApp } from 'vue'


import ElementUI from 'element-plus'
import 'normalize.css/normalize.css'
import '@/styles/variables.scss' // variable css
import '@/styles/index.scss' // global css
import '@/styles/gms-table.scss' // global css
import '@/styles/gms-element.scss' // global css

import 'element-plus/dist/index.css'
import '@/styles/element-variables.scss' // diy theme css
import '@/styles/element-custom.scss' // custom element ui css
import '@/styles/font.css' // custom element ui css


import App from './App.vue'
import store from './store'
import router from './router'
 
import SvgIcon from './components/SvgIcon/index.vue'
import installIcons from '@/icons' // icon
import locale from 'element-plus/es/locale/lang/zh-cn'
import { ElTreeV2 } from 'element-plus'
import { ElAffix } from 'element-plus'
import { ElScrollbar } from 'element-plus'
import VueClipBoard from 'vue-clipboard2'

const app = createApp(App).component('svg-icon', SvgIcon).use(ElTreeV2).use(ElScrollbar)
.use(ElAffix).use(ElementUI, {locale}).use(router).use(store).use(VueClipBoard).mount('#app')
// installIcons(app)

export default {
    namespaced: true
}

