import { getToken , getUserInfo } from '@/utils/auth'
import { addProject, getProjects, deleteProject, updateProject } from '@/api/project'

const getDefaultState = () => {
  return {
    token: getToken(),
    userInfo: getUserInfo(),
    name: '',
    avatar: ''
  }
}

const state = getDefaultState()

const mutations = {
   
}
 
const actions = {
  createproject({ commit }, projectInfo) {
    const { name, description, company, yunxiaoProject } = projectInfo
    return new Promise((resolve, reject) => {
        addProject({ name: name, description: description, companyId: company, yunxiaoProjectId: yunxiaoProject }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  queryprojects({ commit }, param) {
    const { searchName } = param
    return new Promise((resolve, reject) => {
        getProjects({ searchName }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  
  deleteprojectbyid({ commit }, projectInfo) {
    const { projectId } = projectInfo
    return new Promise((resolve, reject) => {
        deleteProject({ projectId: projectId }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  updateproject({ commit }, projectInfo) {
    const { projectId, name, description, company, yunxiaoProject } = projectInfo
    return new Promise((resolve, reject) => {
        updateProject({ id: projectId, name: name, description: description, companyId: company, yunxiaoProjectId: yunxiaoProject }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}