import { getToken , getUserInfo } from '@/utils/auth'
import { addCompany, getCompanyList, deleteCompany, updatecompany } from '@/api/company'

const getDefaultState = () => {
  return {
    token: getToken(),
    userInfo: getUserInfo(),
    name: '',
    avatar: ''
  }
}

const state = getDefaultState()

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState())
  }
}
 
const actions = {
  createcompany({ commit }, companyInfo) {
    const { name, num, email, date } = companyInfo
    return new Promise((resolve, reject) => {
        addCompany({ name: name, num: num, email: email, date: date }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  querycompanylist({ commit }, pageParam) {
    const { pageSize, pageIndex, searchName } = pageParam
    return new Promise((resolve, reject) => {
        getCompanyList({ pageSize: pageSize, pageIndex: pageIndex, searchName: searchName }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  
  deletecompanybyid({ commit }, companyInfo) {
    const { companyId } = companyInfo
    return new Promise((resolve, reject) => {
        deleteCompany({ companyId: companyId }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  updatecompany({ commit }, companyInfo) {
    const { companyId, name, num, email, date } = companyInfo
    return new Promise((resolve, reject) => {
        updatecompany({ id: companyId, name: name, num: num, email: email, date: date }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

