<template>
  <div class="login-container">
    <div class="side-image">
      <img src="@/assets/login_log.png" class="bg_logo_pic"/>
      <img src="@/assets/login_log_name_small.png" class="bg_logo_name" />
    </div>
    <div class="side-background"></div>
    <div style="margin-top: 8%; margin-left: 10%;">
      <div class="title-container">
          <h3 class="title">
            <img src="@/assets/logo_gms_welcome.png">
          </h3>
        </div>


      <div class="form-container">
        <div style="float: left;padding: 20px;">
          <div style="font-size: 18px;margin-bottom: 40px; font-family: PingFang SC, PingFang SC-Regular;color: #999999;">扫码登录</div>
          
          <div class="content" id="self_defined_element" style="width: fit-content;"></div>
          <div style="margin-top: 10px;width: 265px;margin-top: 5px; text-align: center;">使用阿里云APP/支付宝/钉钉</div>
        </div>

        <div style="float: left;width: 1px; height: 281px; background: #d8d8d8;margin-top: 95px;"></div>

        <div style="float: right;">
          <div style="font-size: 18px;margin-bottom: 40px; margin-top: 20px; font-family: PingFang SC, PingFang SC-Regular;color: #999999;">账号密码登录</div>

          <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form" style="padding: 40px" auto-complete="on" label-position="left" v-if="loginbydingding!='false'">
            <div style="font-size: 12px; font-family: PingFang SC, PingFang SC-Medium; font-weight: 600; color: #456076; margin-bottom: 5px;">用户名</div>
            <el-form-item prop="userCode">
              <span class="svg-container">
                <!--svg-icon icon-class="user" /-->
                <el-icon><User /></el-icon>
              </span>
              <el-input
                ref="userCode"
                v-model="loginForm.userCode"
                placeholder="请输入登录账号"
                name="userCode"
                type="text"
                tabindex="1"
                :size="small"
                auto-complete="on"
              />
            </el-form-item>

            <div style="font-size: 12px; font-family: PingFang SC, PingFang SC-Medium; font-weight: 600; color: #456076; margin-bottom: 5px;">密码</div>
            <el-form-item prop="password">
              <span class="svg-container">
                <!--svg-icon icon-class="password" class-name='custom-class'/-->
                <el-icon><Lock /></el-icon>
              </span>
              <el-input
                ref="password"
                v-model="loginForm.password"
                type="password"
                placeholder="请输入密码"
                maxlength="16"
                name="password"
                tabindex="2"
                :size="small"
                auto-complete="on"
                @keyup.enter.native="handleLogin"
                :show-password="true"
              />
            </el-form-item>

            <div class="remember-password">
              <el-checkbox v-model="remember"></el-checkbox>
              <span>记住密码</span>
            </div>
            <el-button class="login-btn" :loading="loading" type="primary" style="width:100%;margin-bottom:30px;" @click.native.prevent="handleLogin">登录</el-button>

            <!-- <div class="tips">
              <span style="margin-right:20px;">userCode: admin</span>
              <span> password: any</span>
            </div> -->
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>
 
<script>
const Base64 = require('js-base64').Base64
import { encrypt, enMD5 } from '@/utils/secret'
import { getCookie, setCookie } from '@/utils/auth'
import { login, logout, getInfo } from '@/api/user'
import { ElMessage } from 'element-plus';
import { User, Lock } from '@element-plus/icons-vue'

export default {
  name: 'Login',
  data() {
    const validateUserCode = (rule, value, callback) => {
      if (!(/^\d{6}$/).test(value) && value !== 'admin'&& value !== 'guest') {
        callback(new Error('请输入正确的账号'))
      } else {
        callback()
      }
    }
    const validatePassword = (rule, value, callback) => {
      if (value.length < 6) {
            callback(new Error('请输入6位以上长度密码'))
        } else {
            callback()
        }
    }
    return {
      User,
      Lock,
      dingdingInitOk: false,
      loginbydingding: true,
      loginForm: {
        userCode: '',
        password: ''
      },
      loginRules: {
        userCode: [{ required: true, trigger: 'blur', validator: validateUserCode }],
        password: [{ required: true, trigger: 'blur', validator: validatePassword }]
      },
      loading: false,
      passwordType: 'password',
      redirect: '',
      remember: false
    }
  },
  methods: {
    handleLogin() {
      console.log(enMD5('000000'))
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.loading = true
          this.$store.dispatch('user/login', {
            userCode: this.loginForm.userCode,
            password: encrypt(this.loginForm.password) //
          }).then(() => {
            // remenber me的设置 setCookie
            this.setUserRemenber()
            this.$router.push({ path: this.redirect || '/' })
            this.loading = false
          }).catch((error) => {
           // ElMessage.error(error)
            this.loading = false
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    setUserRemenber() {
      if(this.remember){
        setCookie("userCode", this.loginForm.userCode)
        let passWord = Base64.encode(this.loginForm.password)
        setCookie("password", passWord)    
      }else{
        setCookie("userCode", "")
        setCookie("password", "")
      } 
    },

    /*****************************dingding**************************** */
    handleDingdingLoginCodeCallback(loginResult){
        if(this.dingdingInitOk) {
          return
        }
        this.dingdingInitOk = true
        const {redirectUrl, authCode, state} = loginResult;
        // 这里可以直接进行重定向
        this.$store.dispatch('dingding/auth', {
          authCode: authCode //
        }).then(() => {
          // remenber me的设置 setCookie
          this.setUserRemenber()
          this.$router.push({ path: '/', query: {} })
          this.loading = false
        }).catch((error) => {
        // ElMessage.error(error)
          this.loading = false
        })
    },
    handleDingdingLoginCode(data) {
          // STEP3：在需要的时候，调用 window.DTFrameLogin 方法构造登录二维码，并处理登录成功或失败的回调。
        window.DTFrameLogin(
            {
                id: 'self_defined_element',
                width: 265,
                height: 265,
            },
            {
                redirect_uri: encodeURIComponent(data.domain),
                client_id: data.client_id,
                scope: 'openid',
                response_type: 'code',
                state:  data.state,
                prompt: 'consent',
            },
            this.handleDingdingLoginCodeCallback
            ,
            (errorMsg) => {
                // 这里一般需要展示登录失败的具体原因,可以使用toast等轻提示
                console.error(`errorMsg of errorCbk: ${errorMsg}`);
            })
    },
    initDingDingConfig() {
      this.$store.dispatch('dingding/config', {
      }).then((data) => {
        this.loading = false
        // 这里通过 `iframe.contentWindow.location` 获取当前 iframe 的 URL
        // this.dingIframeHref = "https://login.dingtalk.com/oauth2/challenge.htm?redirect_uri=" + data.domain + "&response_type=code&client_id=" + data.client_id + "&scope=openid&prompt=consent&state=" + data.state

        this.handleDingdingLoginCode(data)
      }).catch((error) => {
        this.loading = false
      })
    },
    onIframeLoad() {
      const iframe = this.$refs.dingIframe;
      if (iframe == null) {
        return
      }

      try {
        // 这里通过 `iframe.contentWindow.location` 获取当前 iframe 的 URL
        const iframeUrl = iframe.contentWindow.location.href;

        if (iframeUrl.includes('client_id')) {
          return
        }

        // 如果 URL 中包含查询参数（比如 code），进行解析
        if (iframeUrl.includes('?')) {
            const params = new URLSearchParams(iframeUrl.split('?')[1]);
            const code = params.get('code'); // 获取 code 参数
            const state = params.get('state'); // 获取 state 参数

            if (code && state) {
              // 将参数传递到父组件或更新组件状态
              this.loginParams = { code, state };
              this.removeIframe()
              console.log('Login Params:', this.loginParams);
              // 可以在这里调用父组件的函数，或者通过 Vuex 进行全局状态管理
              if (code) {
                console.log('authCode exists:', code);

                this.$store.dispatch('dingding/auth', {
                  authCode: code //
                }).then(() => {
                  // remenber me的设置 setCookie
                  this.setUserRemenber()
                  this.$router.push({ path: '/', query: {} })
                  this.loading = false
                }).catch((error) => {
                // ElMessage.error(error)
                  this.loading = false
                })
              }
            }
          }
      } catch (error) {
        console.error('Error accessing iframe URL:', error);
      }
    },
    removeIframe() {
      const iframeElement = this.$refs.dingIframe;
      if (iframeElement) {
        iframeElement.remove(); // 从 DOM 中删除 iframe
        console.log('Iframe has been removed');
      }
    },
    init() {
      const windowUrl = window.location.href;

      // 如果 URL 中包含查询参数（比如 code），进行解析
      if (windowUrl.includes('?')) {
          const params = new URLSearchParams(windowUrl.split('?')[1]);
          const framestate = params.get('state'); // 获取 code 参数
          if(framestate != null && framestate.includes('bass')) {
            this.isNotIframe = false
          } else {
            this.isNotIframe = true
          }
      } else {
            this.isNotIframe = true
      }

      // this.handleDingdingLoginCode()
      // this.handleDingDingLoginAuth()
    },
  },
  components: {
    User,
    Lock
  },
  mounted: function() {
    let userCode = getCookie("userCode")
    let password = getCookie("password")
    if(userCode && password){
      this.loginForm.userCode = userCode;
      this.loginForm.password = Base64.decode(password);
      this.remember = true;
    }

    this.initDingDingConfig()
    this.init()
  }
}
</script>

<style lang="scss">
/* 修复input 背景不协调 和光标变色 */
/* Detail see https://github.com/PanJiaChen/vue-element-admin/pull/927 */

$bg:#283443;
$light_gray:#fff;
$cursor: #fff;

@supports (-webkit-mask: none) and (not (cater-color: $cursor)) {
  .login-container .el-input input {
    color: $cursor;
  }
}

/* reset element-ui css */
.login-container {
  .el-input {
    display: inline-block;
    height: 45px;
   /* width: 85%;  */
    border: 0px;

    .el-input__wrapper {
        border: 0px;
        background-color:#f1f1f1;
        box-shadow:#f1f1f1 0 0 0 0
    }

    input {
      background: transparent;
      border: 0px;

      border-radius: 0px;
      padding: 12px 5px 12px 15px;
      color: #333;
      height: 42px;
      caret-color: #444;

      &:-webkit-autofill {
        box-shadow: 0 0 0px 1000px #f1f1f1 inset !important;
        -webkit-text-fill-color: #333 !important;
      }
    }
  }

  .el-input__wrapper {
    display: flex;
  }

  .el-form-item__content {
     flex-wrap: revert !important;
  }

  .el-form-item {
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: #f1f1f1;
    border-radius: 5px;
    color: #454545;
    margin-bottom: 25px
  }

  .remember-password {
    text-align: right;
    line-height: 20px;
    margin-top: -7px;
    margin-bottom: 20px;
    font-size: 14px;
    color: #536366;

    .el-checkbox:last-of-type {
      margin-right: 6px;

      .el-checkbox__inner {
        border-color: #d3d3d3;
        border-radius: 1px;
      }

      &.is-checked .el-checkbox__inner {
        border-color: #02C4E7;
        background-color: #02C4E7;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
$dark_gray:#889aa4;
$light_gray:#eee;

.login-container {
  display: flex;
  height: 100%;
  width: 100%;
  background-color: #eaf7fe;
  overflow: hidden;

  .side-image {
    height: 100%;
    width: 26%;
    opacity: 0.8;
    background: linear-gradient(270deg,#daf2fd, #f3faff 99%);

    .bg_logo_pic {
      display: flex;
      margin: auto;
      margin-top: 50%;
    }

    .bg_logo_name {
      display: flex;
      margin: auto;
      margin-top: 53px;
    }
  }

  .side-background {
    height: 100%;
    width: 3%;
    opacity: 0.9;
    background: linear-gradient(270deg, rgba(246, 252, 255, 0), rgba(14, 26, 30, .13) 99%);
  }

  .form-container {
    width: 735px;
    height: 420px;
    background: #ffffff;
    border-radius: 5px;
    box-shadow: 0px 4px 18px 0px rgba(105,147,156,0.23); 
  }

  .login-form {
    position: relative;
    width: 405px;
    /*top: 50%;
    max-width: 100%;
    padding: 0 35px;
    margin: 0 auto;
    margin-top: -200px;*/
    overflow: hidden;
  }

  .login-dingding {
    position: relative;
    top: 27%;
    width: 580px;
    max-width: 100%;
    padding: 0 0px;
    margin: 0 auto;
    margin-top: -200px;
    overflow: hidden;
  }



  .tips {
    font-size: 14px;
    color: red;
    margin-bottom: 10px;

    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }

  .svg-container {
    padding: 6px 5px 6px 15px;
    color: $dark_gray;
    vertical-align: middle;
    width: 30px;
    display: inline-block;
  }

  .title-container {
    position: relative;

    .title {
      display: flex;
      align-items: center;
      font-size: 30px;
      color: #595757;
      margin: 20px auto;
      text-align: center;
      font-weight: bold;
    }

    img {
      display: inline-block;
      margin-right: 10px;
    }
  }

  #iframe_defined_element {
    box-shadow: 0px 4px 18px 0px rgba(105,147,156,0.23); 
  }

  .show-pwd {
    position: absolute;
    right: 10px;
    top: 7px;
    font-size: 16px;
    color: $dark_gray;
    cursor: pointer;
    user-select: none;
  }

  .login-btn {
    background-image: linear-gradient(134deg, #05E4F5 0%, #02C4E7 100%);
    border-radius: 3px;
    border: 0 none;
  }
}

.el-button .el-button--primary {
  background: #00ACCD !important;
}
</style>
