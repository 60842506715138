<template>
    <div class="record-content" v-if="hideEdit">
        <el-row gutter="1"  class="main-content" style="margin-bottom: 20px; box-shadow: 0px 2px 6px 2px rgba(138,152,180,0.14);padding-bottom: 0px;">
            <el-col :span="8" :style="{
                'border': '1px solid rgba(0, 0, 0, .15)',
                'marginRight': '5px',
                'marginLeft': '-10px',
                'marginTop': '-10px',
                'marginBottom': '0px',
                'borderRadius': '4px 4px 0px 0px',
                'boxShadow': '3px 0px 5px 2px rgba(110,123,149,0.23)',
                'background': materialDetail.stateKey == 'on' ? 'linear-gradient(180deg,#49cfdb, #00accd)' : '#ffffff'
                }">
                <el-row :style="{ 'padding': ' 2px', 'background':  'transparent' }">
                    <div style="width: 80%; font-weight: 700; margin: 5px; padding-bottom: 5px;" :style="{ 'color': materialDetail.stateKey == 'on' ? '#FFFFFF' : '#324257'}">{{ materialDetail.materialCode }}</div>
                    <el-icon v-if="uiConfig.isEditBtnShow" style="width: 10%;float: right; margin: 10px;justify-content: end;cursor: pointer;" @click="handleLinkToEdit"  :style="{ color: materialDetail.stateKey === 'on' ? 'white' : 'black' }"><EditPen /></el-icon>
                </el-row>
                <el-row :style="{ 'background': 'transparent' }">  
                    <el-col :span="6"><div class="grid-content" />
                        <el-image style="width: 83px; height: 81px; margin: auto; display: flex; margin-bottom: 10px; margin-top: 5px;" :src="planeImage" :fit="fit" />
                        <div style="width: 100%; color: #324257; font-weight: 700; padding-bottom: 5px; font-size: 12px; justify-content: center;  display: flex;">
                            <div style="width: 10px; height: 10px; border-radius: 50%; margin-right: 10px; align-self: center;" :style="{ 'background': materialDetail.stateKey == 'on' ? 'linear-gradient(180deg,#1de9ff, #00fff7 99%)' : '#777777'}">
                            </div>
                            <div :style="{ 'color': materialDetail.stateKey == 'on' ? 'white' : 'black'}">{{ materialDetail.state }}</div>
                        </div>
                    </el-col>
                    <el-col :span="18"><div class="grid-content" />
                        <el-descriptions :column="2" title="" direction="vertical" style="margin-top: -20px; margin-left: 48px;">
                            <el-descriptions-item label-class-name="detail-label"  class-name="descItem-material" label="型号">
                                <div  :style="{ 'color': materialDetail.stateKey == 'on' ? '#FFFFFF' : '#333333'}">{{ materialDetail.typeName }}</div>
                                <template #label>
                                    <div style="margin-right: 5px;font-size: 12px; margin-top: 10px;" :style="{ 'color': materialDetail.stateKey == 'on' ? 'rgba(255,255,255,0.70)' : '#7E8A95'}">型号</div>
                                </template>
                            </el-descriptions-item>
                            <el-descriptions-item label-class-name="detail-label" class-name="descItem-material" label="所属公司">
                                <div  :style="{ 'color': materialDetail.stateKey == 'on' ? '#FFFFFF' : '#333333'}">{{ materialDetail.company }}</div>
                                <template #label>
                                    <div style="margin-right: 5px;font-size: 12px; margin-top: 10px;" :style="{ 'color': materialDetail.stateKey == 'on' ? 'rgba(255,255,255,0.70)' : '#7E8A95'}">所属公司</div>
                                </template>
                            </el-descriptions-item>

                            <el-descriptions-item label-class-name="detail-label" class-name="descItem-material" label="所属项目">
                                <div  :style="{ 'color': materialDetail.stateKey == 'on' ? '#FFFFFF' : '#333333'}">{{ materialDetail.project }}</div>
                                <template #label>
                                    <div style="margin-right: 5px;font-size: 12px; margin-top: 10px;" :style="{ 'color': materialDetail.stateKey == 'on' ? 'rgba(255,255,255,0.70)' : '#7E8A95'}">所属项目</div>
                                </template>
                            </el-descriptions-item>
                            <el-descriptions-item label-class-name="detail-label" class-name="descItem-material" label="来源">
                                <div  :style="{ 'color': materialDetail.stateKey == 'on' ? '#FFFFFF' : '#333333'}">{{ materialDetail.sourceFrom }}</div>
                                <template #label>
                                    <div style="margin-right: 5px;font-size: 12px; margin-top: 10px;" :style="{ 'color': materialDetail.stateKey == 'on' ? 'rgba(255,255,255,0.70)' : '#7E8A95'}">来源</div>
                                </template>
                            </el-descriptions-item>
                            <el-descriptions-item label-class-name="detail-label" class-name="descItem-material" label="注册时间">
                                <div  :style="{ 'color': materialDetail.stateKey == 'on' ? '#FFFFFF' : '#333333'}">{{ materialDetail.createdTime }}</div>
                                <template #label>
                                    <div style="margin-right: 5px;font-size: 12px; margin-top: 10px;" :style="{ 'color': materialDetail.stateKey == 'on' ? 'rgba(255,255,255,0.70)' : '#7E8A95'}">注册时间</div>
                                </template>
                            </el-descriptions-item>
                            <el-descriptions-item label-class-name="detail-label" class-name="descItem-material" label="生命周期">
                                <div  :style="{ 'color': materialDetail.stateKey == 'on' ? '#FFFFFF' : '#333333'}">{{ materialDetail.phaseStateName }}</div>
                                <template #label>
                                    <div style="margin-right: 5px;font-size: 12px; margin-top: 10px;" :style="{ 'color': materialDetail.stateKey == 'on' ? 'rgba(255,255,255,0.70)' : '#7E8A95'}">生命周期</div>
                                </template>
                            </el-descriptions-item>                        
                        </el-descriptions> 
                    </el-col>
                    <el-col :span="3"><div class="grid-content" />
                    </el-col>
                </el-row>
            </el-col>
            <el-col :span="16"  v-if="materialDetail.channelView =='double'">
                <el-row style="padding: 0px;"><div style="width: 100%; color: #333333; font-size: 12px; font-family: PingFang SC, PingFang SC-Medium; font-weight: 600; margin: 10px; padding-bottom: 5px;">{{ materialDetail.com.type }}</div></el-row>
                <el-row>
                    <el-col :span="24">
                        <el-descriptions :column="6" title="" direction="vertical" style="margin-top: -20px; margin-left: 40px;">
                            <el-descriptions-item label-class-name="detail-label" class-name="descItem-channel" label="上电次数">{{ materialDetail.com.powerTimes }}<span v-if="materialDetail.com.powerTimes != '-'">次</span></el-descriptions-item>
                            <el-descriptions-item label-class-name="detail-label" class-name="descItem-channel" label="运行时长：">{{ materialDetail.com.runTime }}<span v-if="materialDetail.com.runTime != '-'">h</span></el-descriptions-item>
                            <el-descriptions-item label-class-name="detail-label" class-name="descItem-channel" label="DKM版本：" >{{ materialDetail.com.dkm }}</el-descriptions-item>
                            <el-descriptions-item label-class-name="detail-label" class-name="descItem-channel" label="VIP版本：">{{ materialDetail.com.vip }}</el-descriptions-item>
                            <el-descriptions-item label-class-name="detail-label" class-name="descItem-channel" label="FPGA版本：">{{ materialDetail.com.fpga }}</el-descriptions-item>
                            <el-descriptions-item label-class-name="detail-label" class-name="descItem-channel" label="硬件版本：">{{ materialDetail.com.firmware }}</el-descriptions-item>                        
                        </el-descriptions>
                    </el-col>
                </el-row>
                <div style="margin-right: -5px; height:4px; background: #f7f8fa;"></div>
                <el-row style="padding: 0px;"><div style="width: 100%; color: #333333; font-size: 12px; font-family: PingFang SC, PingFang SC-Medium; font-weight: 600; margin: 10px; padding-bottom: 5px;">{{ materialDetail.mon.type }}</div></el-row>
                <el-row>
                    <el-col :span="24">
                        <el-descriptions :column="6" title="" direction="vertical" style="margin-top: -20px; margin-left: 40px;">
                            <el-descriptions-item label-class-name="detail-label" class-name="descItem-channel" label="上电次数">{{ materialDetail.mon.powerTimes }}<span v-if="materialDetail.mon.powerTimes != '-'">次</span></el-descriptions-item>
                            <el-descriptions-item label-class-name="detail-label" class-name="descItem-channel" label="运行时长：">{{ materialDetail.mon.runTime }}<span v-if="materialDetail.mon.runTime != '-'">h</span></el-descriptions-item>
                            <el-descriptions-item label-class-name="detail-label" class-name="descItem-channel" label="DKM版本：" >{{ materialDetail.mon.dkm }}</el-descriptions-item>
                            <el-descriptions-item label-class-name="detail-label" class-name="descItem-channel" label="VIP版本：">{{ materialDetail.mon.vip }}</el-descriptions-item>
                            <el-descriptions-item label-class-name="detail-label" class-name="descItem-channel" label="FPGA版本：">{{ materialDetail.mon.fpga }}</el-descriptions-item>
                            <el-descriptions-item label-class-name="detail-label" class-name="descItem-channel" label="硬件版本：">{{ materialDetail.mon.firmware }}</el-descriptions-item>                        
                        </el-descriptions>
                    </el-col>
                </el-row>
            </el-col>
            <el-col :span="16" v-if="materialDetail.channelView =='single'">
                <el-row></el-row>
                <el-row>
                    <el-col :span="24">
                        <el-descriptions :column="6" title="" direction="vertical" style="margin-top: 40px; margin-left: 40px;">
                            <el-descriptions-item label-class-name="detail-label" class-name="descItem-channel" label="上电次数">{{ materialDetail.single.powerTimes }}<span v-if="materialDetail.single.powerTimes != '-'">次</span></el-descriptions-item>
                            <el-descriptions-item label-class-name="detail-label" class-name="descItem-channel" label="运行时长：">{{ materialDetail.single.runTime }}<span v-if="materialDetail.single.runTime != '-'">h</span></el-descriptions-item>
                            <el-descriptions-item label-class-name="detail-label" class-name="descItem-channel" label="DKM版本：" >{{ materialDetail.single.dkm }}</el-descriptions-item>
                            <el-descriptions-item label-class-name="detail-label" class-name="descItem-channel" label="VIP版本：">{{ materialDetail.single.vip }}</el-descriptions-item>
                            <el-descriptions-item label-class-name="detail-label" class-name="descItem-channel" label="FPGA版本：">{{ materialDetail.single.fpga }}</el-descriptions-item>
                            <el-descriptions-item label-class-name="detail-label" class-name="descItem-channel" label="硬件版本：">{{ materialDetail.single.firmware }}</el-descriptions-item>                        
                        </el-descriptions>
                    </el-col>
                </el-row>
                <el-row></el-row>
            </el-col>
        </el-row>
        <el-row gutter="1"  class="main-content" style="box-shadow: 0px 6px 16px 0px rgba(114,153,168,0.14)">
            <el-col :span="24" class="grid-content">
                <el-tabs v-model="activeTabName" class="demo-tabs" @tab-click="handleClick">
                    <el-tab-pane label="故障记录" name="second">
                        <el-row style="padding-top: 5px; padding-bottom: 5px;">
                            <el-col :span="6" class="right-down-menu"><div class="grid-content ep-bg-purple-light" />
                                <el-date-picker @change="timeDateChanged" v-model="selectedTimeRange" :prefix-icon="Calendar" style="datePicker" size="small" type="datetimerange" range-separator="到" start-placeholder="请选择日期" end-placeholder="请选择日期" />
                            </el-col>
                            <el-col :span="12" class="right-down-menu">
                                <span style="border: 1px solid #898989; height:12px;margin-left:20px"></span>
                                <el-dropdown @command="handleChannelCommand" style="margin-left:20px" trigger="click">
                                    <span class="el-dropdown-link-white">
                                        {{ selectedChannelName }} <el-icon class="el-icon--right"><CaretBottom /></el-icon>
                                    </span>
                                    <template #dropdown>
                                        <el-dropdown-menu>
                                            <el-dropdown-item v-for="(item, index) in channelOptions" :key="item.value" :command="item.value"> {{ item.label }}</el-dropdown-item>
                                        </el-dropdown-menu>
                                    </template>
                                </el-dropdown>
                                <span style="border: 1px solid #898989; height:12px;margin-left:20px"></span>
                                <el-dropdown @command="handleModuleCommand" style="margin-left:20px" trigger="click">
                                    <span class="el-dropdown-link-white">
                                        {{ selectedModuleName }} <el-icon class="el-icon--right"><CaretBottom /></el-icon>
                                    </span>
                                    <template #dropdown>
                                        <el-dropdown-menu>
                                            <el-dropdown-item v-for="(item, index) in moduleOptions" :key="item.value" :command="item.value"> {{ item.label }}</el-dropdown-item>
                                        </el-dropdown-menu>
                                    </template>
                                </el-dropdown>
                                <span style="border: 1px solid #898989; height:12px;margin-left:20px"></span>
                                <!--el-dropdown @command="handleLevelCommand" style="margin-left:20px" trigger="click">
                                    <span class="el-dropdown-link-white">
                                        {{ selectedLevelName }} <el-icon class="el-icon--right"><CaretBottom /></el-icon>
                                    </span>
                                    <template #dropdown>
                                        <el-dropdown-menu>
                                            <el-dropdown-item v-for="(item, index) in levelOptions" :key="item.value" :command="item.value"> {{ item.label }}</el-dropdown-item>
                                        </el-dropdown-menu>
                                    </template>
                                </el-dropdown-->
                                <el-dropdown @command="handleFaultTypCommand" style="margin-left:20px" trigger="click">
                                    <span class="el-dropdown-link-white">
                                        {{ selectedFaultTypeName }} <el-icon class="el-icon--right"><CaretBottom /></el-icon>
                                    </span>
                                    <template #dropdown>
                                        <el-dropdown-menu>
                                            <el-dropdown-item v-for="(item, index) in faultTypeOptions" :key="item.value" :command="item.value"> {{ item.label }}</el-dropdown-item>
                                        </el-dropdown-menu>
                                    </template>
                                </el-dropdown>
                                <span style="border: 1px solid #898989; height:12px;margin-left:20px"></span>
                                <el-dropdown @command="handleStatePhaseCommand" style="margin-left:20px" trigger="click">
                                    <span class="el-dropdown-link-white">
                                        {{ selectedStatePhaseName }} <el-icon class="el-icon--right"><CaretBottom /></el-icon>
                                    </span>
                                    <template #dropdown>
                                        <el-dropdown-menu>
                                            <el-dropdown-item v-for="(item, index) in statePhaseOptions" :key="item.value" :command="item.value"> {{ item.label }}</el-dropdown-item>
                                        </el-dropdown-menu>
                                    </template>
                                </el-dropdown>
                                <!--el-button type="primary" color="#00ACCD" @click="refreshMaterialFaults" class="btn">筛选</el-button-->
                            </el-col>
                            <el-col :span="6" style="text-align: end; padding-top: 1px;">
                                <el-input v-model="searchName" width="200" class="input-fault-search input-with-seach" size="small" placeholder="搜索故障码" :suffix-icon="Search" @keyup.enter.native="refreshMaterialFaults" />
                                <el-button type="primary" color="#00ACCD" @click="exportMaterialFaults" size="small" class="btn">导出</el-button>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="24">
                                <el-table ref="oBDTableRef"  :data="tableData" style="width: 100%; font-weight: 500;"  :height="pageWH.obdtableHeight"  @selection-change="handleSelectionChange">
                                    <el-table-column type="selection" width="55" />
                                    <el-table-column  prop="errorCode" label="故障码" ></el-table-column>
                                    <el-table-column  prop="faultName" label="故障标题" ></el-table-column>
                                    <el-table-column prop="module" label="模块" ></el-table-column>
                                    <el-table-column prop="occurVersion" label="硬件版本" ></el-table-column>
                                    <!--el-table-column prop="level" label="等级"></el-table-column-->
                                    <el-table-column prop="channel" label="通道" v-if="materialDetail.type == '02'"></el-table-column>
                                    <el-table-column prop="type" label="故障类型" ></el-table-column>
                                    <!--el-table-column prop="status" label="状态"></el-table-column-->
                                    <el-table-column prop="occurTimeStr" label="发生时间"  width="230"></el-table-column>
                                    <el-table-column prop="flightPhase" label="飞行阶段"  v-if="materialDetail.type == '02'"></el-table-column>
                                    <!--el-table-column prop="clearTime" label="解决时间" width="150"></el-table-column-->
                                    <el-table-column prop="phase" label="生命周期"></el-table-column>
                                    <el-table-column prop="cnDesc" label="故障内容" width="280"></el-table-column>
                                    <el-table-column prop="ticket" label="">
                                        <template #default="scope">
                                            <el-button link type="primary" @click="handleLink(scope.$index, scope.row)" v-if="scope.row.taskCode != null" style="color:#00ACCD">飞行报告</el-button>
                                            <el-button link type="primary" @click="handleLinkToTicket(scope.$index, scope.row)" v-if="scope.row.yunxiaoTicket != null" style="color:#00ACCD">工单</el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="9" class="grid-content"></el-col>
                            <el-col :span="15" class="pager-content">
                                <el-pagination
                                    v-model:current-page="currentPage"
                                    v-model:page-size="pageSize"
                                    :page-sizes="[10, 20, 50, 100, 200]"
                                    :size="paginationSize"
                                    small="true"
                                    :disabled="disabled"
                                    :background="background"
                                    layout="total, prev, pager, next, sizes, jumper"
                                    :total="totalNum"
                                    @size-change="handleSizeChange"
                                    @current-change="handleCurrentChange"
                                    class="float_right"
                                    />
                            </el-col>
                        </el-row>
                    </el-tab-pane>
                    <el-tab-pane label="飞行记录" name="first">
                        <el-row style="padding-top: 5px; padding-bottom: 5px;">
                            <el-col :span="6" class="right-down-menu"><div class="grid-content ep-bg-purple-light" />
                                <el-date-picker  @change="timeDateChanged" v-model="selectedTimeRange"  :prefix-icon="Calendar" style="datePicker" size="small" type="datetimerange" range-separator="到" start-placeholder="请选择日期" end-placeholder="请选择日期" />
                            </el-col>
                            <el-col :span="10" class="right-down-menu">
                                <span style="border: 1px solid #898989; height:12px; margin-left:20px"></span>
                                <el-dropdown @command="handleTaskStateCommand" style="margin-left:20px" trigger="click">
                                    <span class="el-dropdown-link-white">
                                        {{ selectedTaskStateName }} <el-icon class="el-icon--right"><CaretBottom /></el-icon>
                                    </span>
                                    <template #dropdown>
                                        <el-dropdown-menu>
                                            <el-dropdown-item v-for="(item, index) in taskStateOptions" :key="item.value" :command="item.value"> {{ item.label }}</el-dropdown-item>
                                        </el-dropdown-menu>
                                    </template>
                                </el-dropdown>
                                <span style="border: 1px solid #898989; height:12px;margin-left:20px"></span>
                                <el-dropdown @command="handleStatePhaseCommand" style="margin-left:20px" trigger="click">
                                    <span class="el-dropdown-link-white">
                                        {{ selectedStatePhaseName }} <el-icon class="el-icon--right"><CaretBottom /></el-icon>
                                    </span>
                                    <template #dropdown>
                                        <el-dropdown-menu>
                                            <el-dropdown-item v-for="(item, index) in statePhaseOptions" :key="item.value" :command="item.value"> {{ item.label }}</el-dropdown-item>
                                        </el-dropdown-menu>
                                    </template>
                                </el-dropdown>
                                <!--el-button type="primary" color="#00ACCD" @click="refreshMaterialTask" class="btn">筛选</el-button-->
                            </el-col>
                            <el-col :span="2"></el-col>
                            <el-col :span="6" style="text-align: end;padding-top: 1px;">
                                <el-input v-model="searchName" class="input-task-search input-with-seach" size="small" placeholder="搜索任务编号" :suffix-icon="Search" @keyup.enter.native="refreshMaterialTask" />
                                <el-button type="primary" color="#00ACCD" @click="exportFlightRecord" size="small" class="btn">导出</el-button>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="24">
                                <el-table :data="taskTableData" style="width: 100%; font-weight: 500;" :height="pageWH.obdtableHeight">
                                    <el-table-column  prop="taskCode" label="任务编号"></el-table-column>
                                    <el-table-column prop="startTime" label="任务时间"></el-table-column>
                                    <el-table-column prop="status" label="状态"></el-table-column>
                                    <el-table-column prop="taskInfo" label="基本信息">
                                        <template #default="scope">
                                                <div>总距离：{{ scope.row.totalDistanceKM }} km</div>
                                                <div>总时长：{{ scope.row.totalTimeCost }}</div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="phase" label="生命周期"></el-table-column>
                                    <el-table-column prop="operation" label="操作">
                                        <template #default="scope">
                                            <el-button link type="primary" style="color:#00ACCD" @click="handleLink(scope.$index, scope.row)">飞行报告</el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="9" class="grid-content"></el-col>
                            <el-col :span="15" class="pager-content">
                                <el-pagination
                                    v-model:current-page="taskTableCurrentPage"
                                    v-model:page-size="taskTablePageSize"
                                    :page-sizes="[10, 20, 50, 100, 200]"
                                    :size="paginationSize"
                                    small="true"
                                    :disabled="disabled"
                                    :background="background"
                                    layout="total, prev, pager, next, sizes, jumper"
                                    :total="taskTableTotalNum"
                                    @size-change="handleTaskTableSizeChange"
                                    @current-change="handleTaskTableCurrentChange"
                                    class="float_right"
                                    />
                            </el-col>
                        </el-row>
                    </el-tab-pane>
                    <el-tab-pane label="测试记录" name="third">
                        <el-row style="padding-top: 5px; padding-bottom: 5px;">
                            <el-col :span="6" class="right-down-menu"><div class="grid-content ep-bg-purple-light" />
                                <el-date-picker  @change="timeDateChanged" v-model="selectedTimeRange"  :prefix-icon="Calendar" style="datePicker" size="small" type="datetimerange" range-separator="到" start-placeholder="请选择日期" end-placeholder="请选择日期" />
                            </el-col>
                            <el-col :span="10" class="right-down-menu">
                                <span style="border: 1px solid #898989; height:12px;margin-left:20px"></span>
                                <el-dropdown @command="handleChannelCommand" style="margin-left:20px" trigger="click">
                                    <span class="el-dropdown-link-white">
                                        {{ selectedChannelName }} <el-icon class="el-icon--right"><CaretBottom /></el-icon>
                                    </span>
                                    <template #dropdown>
                                        <el-dropdown-menu>
                                            <el-dropdown-item v-for="(item, index) in channelOptions" :key="item.value" :command="item.value"> {{ item.label }}</el-dropdown-item>
                                        </el-dropdown-menu>
                                    </template>
                                </el-dropdown>
                                <span style="border: 1px solid #898989; height:12px;margin-left:20px"></span>
                                <el-dropdown @command="handleStatePhaseCommand" style="margin-left:20px" trigger="click">
                                    <span class="el-dropdown-link-white">
                                        {{ selectedStatePhaseName }} <el-icon class="el-icon--right"><CaretBottom /></el-icon>
                                    </span>
                                    <template #dropdown>
                                        <el-dropdown-menu>
                                            <el-dropdown-item v-for="(item, index) in statePhaseOptions" :key="item.value" :command="item.value"> {{ item.label }}</el-dropdown-item>
                                        </el-dropdown-menu>
                                    </template>
                                </el-dropdown>
                                <!--el-button type="primary" color="#00ACCD" @click="refreshTestRecords" class="btn">筛选</el-button-->
                            </el-col>
                            <el-col :span="6"></el-col>
                            <el-col :span="2" style="text-align: end; padding-top: 3px;"><el-button type="primary" color="#00ACCD" @click="exportTestRecords" size="small" class="btn">导出</el-button></el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="24">
                                <el-table ref="testRecordTableRef"  :data="tableDataTestRecord" style="width: 100%; font-weight: 500;"  :height="pageWH.obdtableHeight"  @selection-change="handleSelectionChangeTestRecord">
                                    <el-table-column type="selection" width="55" />
                                    <el-table-column  prop="testTime" label="测试时间"></el-table-column>
                                    <el-table-column prop="testUserName" label="测试人员"></el-table-column>
                                    <el-table-column prop="testChannel" label="测试通道" v-if="materialDetail.type == '02' "></el-table-column>
                                    <el-table-column prop="testType" label="测试类型"></el-table-column>
                                    <el-table-column prop="phase" label="周期"  width="240"></el-table-column>
                                    <el-table-column prop="testResult" label="测试结果" width="160"></el-table-column>
                                    <el-table-column prop="operation" label="操作">
                                        <template #default="scope">
                                            <el-button link type="primary" style="color:#00ACCD" @click="handleTestItemView(scope.$index, scope.row)">查看</el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="9" class="grid-content"></el-col>
                            <el-col :span="15" class="pager-content">
                                <el-pagination
                                    v-model:current-page="currentPageTestRecord"
                                    v-model:page-size="pageSizeTestRecord"
                                    :page-sizes="[10, 20, 50, 100, 200]"
                                    :size="paginationSize"
                                    small="true"
                                    :disabled="disabled"
                                    :background="background"
                                    layout="total, prev, pager, next, sizes, jumper"
                                    :total="totalNumTestRecord"
                                    @size-change="handleSizeChangeTestRecord"
                                    @current-change="handleCurrentChangeTestRecord"
                                    class="float_right"
                                    />
                            </el-col>
                        </el-row>
                    </el-tab-pane>
                    <el-tab-pane label="维护记录" name="four">
                        <el-row style="padding-top: 5px; padding-bottom: 5px;">
                            <el-col :span="6" class="right-down-menu"><div class="grid-content ep-bg-purple-light" />
                                <el-date-picker  @change="timeDateChanged" v-model="selectedTimeRange"  :prefix-icon="Calendar" style="datePicker" size="small" type="datetimerange" range-separator="到" start-placeholder="请选择日期" end-placeholder="请选择日期" />
                            </el-col>
                            <el-col :span="10" class="right-down-menu">
                                <span style="border: 1px solid #898989; height:12px;margin-left:20px"></span>
                                <el-dropdown @command="handleChannelCommand" style="margin-left:20px" trigger="click">
                                    <span class="el-dropdown-link-white">
                                        {{ selectedChannelName }} <el-icon class="el-icon--right"><CaretBottom /></el-icon>
                                    </span>
                                    <template #dropdown>
                                        <el-dropdown-menu>
                                            <el-dropdown-item v-for="(item, index) in channelOptions" :key="item.value" :command="item.value"> {{ item.label }}</el-dropdown-item>
                                        </el-dropdown-menu>
                                    </template>
                                </el-dropdown>
                                <span style="border: 1px solid #898989; height:12px;margin-left:20px"></span>
                                <el-dropdown @command="handleMaintUserCommand" style="margin-left:20px" trigger="click">
                                    <span class="el-dropdown-link-white">
                                        {{ selectedMaintUserName }} <el-icon class="el-icon--right"><CaretBottom /></el-icon>
                                    </span>
                                    <template #dropdown>
                                        <el-dropdown-menu>
                                            <el-dropdown-item v-for="(item, index) in maintUserOptions" :key="item.id" :command="item.id"> {{ item.name }}</el-dropdown-item>
                                        </el-dropdown-menu>
                                    </template>
                                </el-dropdown>
                                <span style="border: 1px solid #898989; height:12px;margin-left:20px"></span>
                                <el-dropdown @command="handleStatePhaseCommand" style="margin-left:20px" trigger="click">
                                    <span class="el-dropdown-link-white">
                                        {{ selectedStatePhaseName }} <el-icon class="el-icon--right"><CaretBottom /></el-icon>
                                    </span>
                                    <template #dropdown>
                                        <el-dropdown-menu>
                                            <el-dropdown-item v-for="(item, index) in statePhaseOptions" :key="item.value" :command="item.value"> {{ item.label }}</el-dropdown-item>
                                        </el-dropdown-menu>
                                    </template>
                                </el-dropdown>
                                <!--el-button type="primary" color="#00ACCD" @click="refreshMaintanceRecords" class="btn">筛选</el-button-->
                            </el-col>
                            <el-col :span="6"></el-col>
                            <el-col :span="2" style="text-align: end;padding-top: 3px;"><el-button type="primary" color="#00ACCD" @click="exportMaintanceRecords" size="small" class="btn">导出</el-button></el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="24">
                                <el-table ref="maintanceRecordTableRef"  :data="maintanceTableData" style="width: 100%; font-weight: 500;"  :height="pageWH.obdtableHeight"  @selection-change="handleSelectionChangeMaintanceRecord">
                                    <el-table-column type="selection" width="55" />
                                    <el-table-column type="expand">
                                        <template #default="props">
                                            <div m="4" class="maintanceDetailExpandRow">
                                                <!--h3>故障详情</h3-->
                                                <el-table :data="props.row.faults" :border="childBorder">
                                                    <el-table-column label="故障码" prop="faultCode" />
                                                    <el-table-column label="故障标题" prop="faultName" />
                                                    <el-table-column label="故障模块" prop="faultModule" />
                                                    <el-table-column label="通道" prop="channel" v-if="materialDetail.type == '02'" />
                                                    <el-table-column label="发生时间" prop="occurTime" />
                                                    <el-table-column label="飞行阶段" prop="flightPhase" />
                                                    <el-table-column label="故障内容" prop="description" />
                                                </el-table>
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column  prop="maintTime" label="维护时间"></el-table-column>
                                    <el-table-column prop="maintUserName" label="维护人员"></el-table-column>
                                    <el-table-column prop="clearFaults" label="清除故障"></el-table-column>
                                    <el-table-column prop="phase" label="周期"  width="240"></el-table-column>
                                </el-table>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="9" class="grid-content"></el-col>
                            <el-col :span="15" class="pager-content">
                                <el-pagination
                                    v-model:current-page="currentPageMaintRecord"
                                    v-model:page-size="pageSizeMaintRecord"
                                    :page-sizes="[10, 20, 50, 100, 200]"
                                    :size="paginationSize"
                                    small="true"
                                    :disabled="disabled"
                                    :background="background"
                                    layout="total, prev, pager, next, sizes, jumper"
                                    :total="totalNumMaintRecord"
                                    @size-change="handleSizeChangeMaintRecord"
                                    @current-change="handleCurrentChangeMaintRecord"
                                    class="float_right"
                                    />
                            </el-col>
                        </el-row>
                    </el-tab-pane>
                    <el-tab-pane label="运行日志" name="five">
                        <el-row style="padding-top: 5px; padding-bottom: 5px;">
                            <el-col :span="6" class="right-down-menu"><div class="grid-content ep-bg-purple-light" />
                                <el-date-picker  @change="timeDateChanged" v-model="selectedTimeRange"  :prefix-icon="Calendar" style="datePicker" size="small" type="datetimerange" range-separator="到" start-placeholder="请选择日期" end-placeholder="请选择日期" />
                            </el-col>
                            <el-col :span="8" class="right-down-menu">
                                <span style="border: 1px solid #898989; height:12px;margin-left:20px"></span>
                                <el-dropdown @command="handleChannelCommand" style="margin-left:20px" trigger="click">
                                    <span class="el-dropdown-link-white">
                                        {{ selectedChannelName }} <el-icon class="el-icon--right"><CaretBottom /></el-icon>
                                    </span>
                                    <template #dropdown>
                                        <el-dropdown-menu>
                                            <el-dropdown-item v-for="(item, index) in channelOptions" :key="item.value" :command="item.value"> {{ item.label }}</el-dropdown-item>
                                        </el-dropdown-menu>
                                    </template>
                                </el-dropdown>
                                <span style="border: 1px solid #898989; height:12px;margin-left:20px"></span>
                                <el-dropdown @command="handleStatePhaseCommand" style="margin-left:20px" trigger="click">
                                    <span class="el-dropdown-link-white">
                                        {{ selectedStatePhaseName }} <el-icon class="el-icon--right"><CaretBottom /></el-icon>
                                    </span>
                                    <template #dropdown>
                                        <el-dropdown-menu>
                                            <el-dropdown-item v-for="(item, index) in statePhaseOptions" :key="item.value" :command="item.value"> {{ item.label }}</el-dropdown-item>
                                        </el-dropdown-menu>
                                    </template>
                                </el-dropdown>
                                <!--el-button type="primary" color="#00ACCD" @click="refreshRunnerLogs" class="btn">筛选</el-button-->
                            </el-col>
                            <el-col :span="8"></el-col>
                            <el-col :span="2" style="text-align: end;padding-top: 3px;"><el-button type="primary" color="#00ACCD" @click="exportRunnerLogs" size="small" class="btn">导出</el-button></el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="24">
                                <el-table ref="runnerLogTableRef"  :data="tableDataRunLog" style="width: 100%; font-weight: 500;"  :height="pageWH.obdtableHeight"  @selection-change="handleSelectionChangeRunnerLog">
                                    <el-table-column type="selection" width="55" />
                                    <el-table-column  prop="logName" label="日志名称"></el-table-column>
                                    <el-table-column prop="logType" label="类型"></el-table-column>
                                    <el-table-column prop="channel" label="通道" v-if="materialDetail.type == '02'"></el-table-column>
                                    <el-table-column prop="powerTime" label="设备上电次数"></el-table-column>
                                    <el-table-column prop="size" label="大小"  width="240"></el-table-column>
                                    <el-table-column prop="time" label="时间" width="160"></el-table-column>
                                    <el-table-column prop="phase" label="周期" width="130"></el-table-column>
                                    <el-table-column prop="operation" label="操作">
                                        <template #default="scope">
                                            <el-button link type="primary" style="color:#00ACCD" @click="handleLogView(scope.$index, scope.row)">查看</el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="9" class="grid-content"></el-col>
                            <el-col :span="15" class="pager-content">
                                <el-pagination
                                    v-model:current-page="currentPageRunnerlog"
                                    v-model:page-size="pageSizeRunnerlog"
                                    :page-sizes="[10, 20, 50, 100, 200]"
                                    :size="paginationSize"
                                    small="true"
                                    :disabled="disabled"
                                    :background="background"
                                    layout="total, prev, pager, next, sizes, jumper"
                                    :total="totalNumRunnerlog"
                                    @size-change="handleSizeChangeRunnerlog"
                                    @current-change="handleCurrentChangeRunnerlog"
                                    class="float_right"
                                    />
                            </el-col>
                        </el-row>
                    </el-tab-pane>
                </el-tabs>
            </el-col>
        </el-row>

        <el-tabs v-model="activeChannleTabName" v-if="materialDetail.channelView=='double'" class="demo-tabs" @tab-click="handleDataChannelClick" type="card" style="margin-top: 20px;">
            <el-tab-pane label="全部数据" name="allData"></el-tab-pane>
            <el-tab-pane label="COM通道" name="com"></el-tab-pane>
            <el-tab-pane label="MON通道" name="mon"></el-tab-pane>
        </el-tabs>
        <el-row :gutter="20" style="margin:1px; margin-top:0px; background: #fff;box-shadow: 0px 6px 16px 0px rgba(114,153,168,0.14);" :style="{ marginTop: materialDetail.channelView !== 'double' ? '20px' : '0px' }">
            <el-col :span="24" style="background: #fff; margin:1px; padding: 1px; margin-bottom: -5px;">
                <div class="bottom-content ep-bg-purple">
                    <div class="title-fault" style="width: 100%;margin-bottom:5px; padding-bottom: 5px; border-bottom:1px solid #e9e9e9;height: 30px;">
                        <div style="float:left; font-weight: 600; color: #333333; font-size: 14px;">设备生命周期趋势数据</div>
                        <el-radio-group v-model="radioChartType" style="float:right; margin-right: 20px;" @change="chartRadioChange">
                            <el-radio  label="fault" name="fault">故障</el-radio>
                            <el-radio label="powertimes" name="powertimes"  v-if="materialDetail.type!='01'">上电次数</el-radio>
                            <el-radio label="runtime" name="runtime"  v-if="materialDetail.type!='01'">运行时长</el-radio>
                            <el-radio label="flighttimes" name="flighttimes" v-if="activeChannleTabName=='allData'">飞行架次</el-radio>
                            <!--el-radio label="flighttime" name="flighttime" v-if="activeChannleTabName=='allData'">飞行时长</el-radio-->
                            <el-radio label="datasize" name="datasize" v-if="activeChannleTabName=='allData'">数据量</el-radio>
                        </el-radio-group>
                    </div>
                    <div id='flightPanel' style='width: 100%;' :style="{ height: pageWH.pieHeight + 'px' }"></div>
                </div>
            </el-col>
        </el-row>  
    </div>

    <el-row v-if="showEdit"  style="box-shadow: 0px 2px 6px 2px rgba(138,152,180,0.14); ">
        <div class="title-flag" style="color: #333333; width: 100%; font-weight: 700; margin: 10px; padding-bottom: 5px; border-bottom: #c4f0f8 1px solid;">
            基本信息
        </div>
        <el-col>
            <el-form   ref="materialform" :model="materialform" label-position="left">
                <el-row>
                    <el-col :span="14">
                        <el-form-item label="编号" :label-width="formLabelWidth">
                            <el-input v-model="materialform.materialCode" autocomplete="off" placeholder="输入设备编号"  disabled="true" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="2">
                    </el-col>
                    <el-col :span="8"></el-col>
                </el-row>
                
                <el-row>
                    <el-col :span="14" class="editdatepicker">
                        <el-form-item label="注册日期" :label-width="formLabelWidth">
                            <el-date-picker
                                v-model="materialform.createdTime"
                                type="date"
                                placeholder="选择注册日期"
                                disabled="true"
                                :prefix-icon="customPrefix"
                                style="width: -webkit-fill-available !important; width:320px"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="2">
                    </el-col>
                    <el-col :span="8"></el-col>
                </el-row>

                <el-row>
                    <el-col :span="8">
                        <el-form-item label="所属型号" :label-width="formLabelWidth">
                            <el-select v-model="materialform.type" placeholder="请选择类型" clearable :suffix-icon="CaretBottom">
                                <el-option
                                    v-for="item in typeOptions"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8"></el-col>
                    <el-col :span="8"></el-col>
                </el-row>  
                
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="所属公司" :label-width="formLabelWidth">
                            <el-select v-model="materialform.company" filterable placeholder="请选择所属公司" @change="handleCompanySelectChange" :suffix-icon="CaretBottom">
                                <el-option
                                    v-for="item in companyOptions"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8"></el-col>
                    <el-col :span="8"></el-col>
                </el-row>
                
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="所属项目" :label-width="formLabelWidth">
                            <el-select v-model="materialform.projectId" placeholder="请选择项目" :suffix-icon="CaretBottom">
                                <el-option
                                    v-for="item in projectSelectOptions"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8"></el-col>
                    <el-col :span="8"></el-col>
                </el-row> 

                <el-row>
                    <el-col :span="8">
                        <el-form-item label="生命周期" :label-width="formLabelWidth">
                            <el-select v-model="materialform.lifecyclePhase" placeholder="请选择周期" :suffix-icon="CaretBottom">
                                <el-option
                                    v-for="item in lifecyclePhaseOptions"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8"></el-col>
                    <el-col :span="8"></el-col>
                </el-row> 
            </el-form>
        </el-col>
    </el-row>
    <span v-if="showEdit"  class="form-footer" :style="{ 'margin-bottom': pageWH.editMarginHeight + 'px' }">
        <el-button type="primary" @click="updateMaterialById" color="#00ACCD" style="padding-left:40px;padding-right:40px;">保存</el-button>
        <el-button @click="cancelEdit" style="padding-left:40px;padding-right:40px; padding-top: 10px; padding-bottom: 10px;border: #cccccca1 1px solid !important; ">取消</el-button>
    </span>
    
    <el-dialog v-model="showLogVisible" title="查看日志" width="620px">
        <div style="color:#292827; font-size: 12px">
            <table style="width: 100%;height: 60px;">
                <thead>
                    <tr>
                        <th style="margin-bottom:10px; text-align: justify;font-weight: 200;font-size: 12px;">设备</th>
                        <th style="margin-bottom:10px; text-align: justify;font-weight: 200;font-size: 12px;">类型</th>
                        <th style="margin-bottom:10px; text-align: justify;font-weight: 200;font-size: 12px;">大小</th>
                        <th style="margin-bottom:10px; text-align: justify;font-weight: 200;font-size: 12px;">时间</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td id="device" style="font-weight: 600;font-size: 14px;">{{ logInfo.sn }}</td>
                        <td id="type" style="font-weight: 600;font-size: 14px;">{{ logInfo.type }}</td>
                        <td id="size" style="font-weight: 600;font-size: 14px;">{{ logInfo.size }}</td>
                        <td id="time" style="font-weight: 600;font-size: 14px;">{{ logInfo.time }}</td>
                    </tr>
                </tbody>
            </table>
            <div style="height: 6px; width: 100%; background: #f1f1f1; width: 620px; margin-left: -20px;"></div>
            <div style="margin-top: 20px;">
                <h5 style="margin-bottom:5px">日志内容</h5>
                <pre id="log-content" class="scrollable-content" style="background: #f5f5f5; border: 1px solid #dbdbdb;height: 280px; max-height: 280px; overflow: scroll;">{{ logContent }}</pre>
            </div>
        </div>
        <template #footer>
            <span class="dialog-footer">
                <el-button type="primary" @click="exportLogInfo" color="#00ACCD">导出</el-button>
            </span>
        </template>
    </el-dialog>

    <el-dialog v-model="showTestRecordVisible" title="测试记录" width="720px">
        <div style="color:#3c3a36; font-size: 12px">
            <table style="width: 100%;height: 60px;">
                <thead>
                    <tr>
                        <th style="margin-bottom:10px; text-align: justify;font-weight: 200;font-size: 12px;">设备</th>
                        <th style="margin-bottom:10px; text-align: justify;font-weight: 200;font-size: 12px;">测试类型</th>
                        <th style="margin-bottom:10px; text-align: justify;font-weight: 200;font-size: 12px;">测试通道</th>
                        <th style="margin-bottom:10px; text-align: justify;font-weight: 200;font-size: 12px;">测试人员</th>
                        <th style="margin-bottom:10px; text-align: justify;font-weight: 200;font-size: 12px;">时间</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td id="device" style="font-weight: 600;font-size: 14px;">{{ testInfo.sn }}</td>
                        <td id="type" style="font-weight: 600;font-size: 14px;">{{ testInfo.type }}</td>
                        <td id="testChannel" style="font-weight: 600;font-size: 14px;">{{ testInfo.testChannel }}</td>
                        <td id="size" style="font-weight: 600;font-size: 14px;">{{ testInfo.userName }}</td>
                        <td id="time" style="font-weight: 600;font-size: 14px;">{{ testInfo.time }}</td>
                    </tr>
                </tbody>
            </table>
            <div style="height: 6px; width: 100%; background: #f1f1f1; width: 720px; margin-left: -20px;"></div>
            <div style="margin-top: 20px;">
                <el-table ref="testInfoTableRef"  :data="testInfo.list" style="width: 100%; font-weight: 500;"  :height="300">
                    <el-table-column  prop="testItemName" label="测试内容"></el-table-column>
                    <el-table-column prop="testItemResult" label="结果">
                        <template #default="scope">
                            <div v-if="scope.row.testItemResult === '通过'" style="font-weight: 600; color: green;">
                                <el-icon style="color: green; margin-right: 5px;" ><Check /></el-icon>
                                {{ scope.row.testItemResult }}
                            </div>
                            <div v-else-if="scope.row.testItemResult === '不通过'" style="font-weight: 600; color: red;">
                                <el-icon style="color: red; margin-right: 5px;" ><CloseBold /></el-icon>
                                {{ scope.row.testItemResult }}
                            </div>
                            <div v-else style="font-weight: 600;">
                                {{ scope.row.testItemResult }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="failDesc" label="备注">
                        <template #default="scope">
                                <div style="font-weight:600;color:#333333">{{ scope.row.failDesc }}</div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <template #footer>
            <div v-if="testInfo.testResult=== '通过'" style="font-weight: 600; color: green; float: left;font-size: 12px;margin-left:10px;margin-top:5px">
                {{ testInfo.testResult }}
            </div>
            <div v-else-if="testInfo.testResult === '不通过'" style="font-weight: 600; color: red; float: left;font-size: 12px;margin-left:10px;margin-top:5px">
                {{ testInfo.testResult }}
            </div>
            <span class="dialog-footer">
                <el-button type="primary" @click="showTestRecordVisible=false" color="#00ACCD">确定</el-button>
            </span>
        </template>
    </el-dialog>
  </template>
  
  
  <script>
  import { Search, Plus, Check, CaretBottom, EditPen, Calendar, CloseBold } from "@element-plus/icons-vue";
  import { reactive } from 'vue'
  import axios from 'axios';
  import * as echarts from 'echarts';
  import 'echarts-gl'
  import moment from 'moment'
  import storage from '@/utils/storage'
  import { ElMessage } from 'element-plus';
  import config from '@/utils/config.json';

   // 如果 script用了setup, 则不能用export，可对比之前的文件
  export default {
    name: 'CompanyManager',
    data() {
            const activeTabName = 'second'
            const handleClick = (tab, event) => {
               // console.log(tab, event)
                this.selectedTimeRange = []
                if (tab.props.name == 'second') {
                    this.currentPage = 1
                    this.pageSize = 10
                    this.refreshMaterialFaults()
                    return
                }

                if (tab.props.name == 'first') {
                    this.taskTableCurrentPage = 1
                    this.taskTablePageSize = 10
                    this.refreshMaterialTask()
                    return
                }

                this.selectedChannel = ""
                this.selectedStatePhase = ""
                this.selectedChannelName = this.channelOptionsMap[this.selectedChannel]
                this.selectedStatePhaseName = this.statePhaseOptionsMap[this.selectedStatePhase]

                this.currentPageTestRecord = 1
                this.pageSizeTestRecord = 10

                this.currentPageMaintRecord = 1
                this.pageSizeMaintRecord = 10

                this.currentPageRunnerlog = 1
                this.pageSizeRunnerlog = 10
                this.refreshTableData(tab.props.name)
            }
                
            const handleNodeClick = (data) => {
                console.log(data)
                this.planeForm.name = data.name;
                this.planeForm.description = data.description
                this.currClickNode = data
                this.selectedPlane = this.currClickNode.rights.split(',')
                this.planeDesc = data.description ? data.description : ''
                this.createdBy = data.createdByUser ? data.createdByUser : ''
                this.createdTime = data.createdTime ? data.createdTime : ''
            }
 
            const planeForm = reactive({
                id: '',
                name: '',
                description: '',
                rights: []
            })
            const tableForm = []   
            const tableData = []
            const taskTableData = []
            const materialDetail = {
                company: '',
                companeyId: '',
                createdTime: '',
                // Scrap_remaining: '300天',
                // bindingTime: '',
                // director: '陶',
                typeName: '',
                stateKey: 'off',
                mon: {},
                com: {},
                single: {

                },
                channelView: "single",
                extendDetail: {
                    "totalComFault": 0,
                    "totalFault": 0,
                    "totalMonFault": 0
                }
             }
 
            const statusMap  = {
                on: '在线',
                off: '离线'
            }

            const errorStatusMap  = {
                1: '未解决',
                0: '已解决'
            }

            const moduleOptionsMap = 
            {
                '0': '系统软件',
                "": '所有模块',
            }
            const taskStatusMap = {
                'flying': '飞行中',
                'landed': '已完成',
                'timeOut': '已超时',
            }
            const taskStateOptions = [{
                    value: '',
                    label: '全部状态',
                },
                {
                    value: 'flying',
                    label: '飞行中',
                },
                {
                    value: 'landed',
                    label: '已完成',
                },
                {
                    value: 'timeOut',
                    label: '已超时',
                }
            ]
            const statePhaseOptionsMap = {
                '': '全部周期',
                '1': '研发阶段',
                '2': '服役阶段',
                '3': '维修阶段',
                '4': '报废阶段'
            }
            const channelOptionsMap = {
                '': '全部通道',
                '0': 'COM通道',
                '1': 'MON通道'
            }
            const faultTypeOptionsMap = {}
            const faultTypeOptions = []
            const R3faultTypeOptions = [
                 {
                    value: '',
                    label: '全部类型',
                },
                {
                    value: '0',
                    label: '永久故障',
                },
                {
                    value: '1',
                    label: '实时故障',
                }
            ]
            const REG300faultTypeOptions = [
                 {
                    value: '',
                    label: '全部类型',
                },
                {
                    value: '0',
                    label: '锁存故障',
                },
                {
                    value: '1',
                    label: '常规故障',
                }
            ]
            const channelOptions = [
                 {
                    value: '',
                    label: '全部通道',
                },
                {
                    value: '0',
                    label: 'COM通道',
                },
                {
                    value: '1',
                    label: 'MON通道',
                }
            ]
            const statePhaseOptions = [
                 {
                    value: '',
                    label: '全部周期',
                },
                {
                    value: '1',
                    label: '研发阶段',
                },
                {
                    value: '2',
                    label: '服役阶段',
                },
                {
                    value: '3',
                    label: '维修阶段',
                },
                {
                    value: '4',
                    label: '报废阶段',
                }
            ]
            const moduleOptions = [
                 {
                    value: '',
                    label: '所有模块',
                },
                {
                    value: '0',
                    label: '系统软件',
                }
            ]
            const levelOptions = [
                {
                    value: '',
                    label: '所有等级',
                },
                {
                    value: '1',
                    label: '等级1',
                },
                {
                    value: '2',
                    label: '等级2',
                },
                {
                    value: '3',
                    label: '等级3',
                },
                {
                    value: '4',
                    label: '等级4',
                }
            ]
            const formLabelWidth = '100px'
            const planeImage = require('@/assets/device_medium.png')
            const showPlaneEdit = false
            const offLineView = true
            const treeData = []
            const selectedPlane = []
            const currClickNode = {}
            const companyMap = {}
            const searchName = ''
            const createdBy = ''
            const createdTime = ''
            const planeDesc = ''
            const editingPlaneId = ''
            const selectedTimeRange = null
            const selectedModule = ''
            const selectedModuleName = '全部模块'
            const selectedLevelName = '全部等级'
            const selectedChannelName = "全部通道"
            const selectedStatePhaseName = "全部周期"
            const selectedTaskStateName = "全部状态"
            const selectedFaultTypeName = "全部类型"
            const selectedMaintUserName = "全部维护人员"
            const selectedLevel = ''
            const totalNum = 0
            const currentPage = 1
            const pageSize = 10
            const taskTableCurrentPage = 1
            const taskTablePageSize = 10
            const currentPageMaintRecord = 1
            const pageSizeMaintRecord = 10
            const currentPageTestRecord = 1
            const pageSizeTestRecord = 10
            const currentPageRunnerlog = 1
            const pageSizeRunnerlog =10
            const currMaterialId = ""
            const taskTableTotalNum = 0
            const totalNumTestRecord = 0
            const totalNumMaintRecord = 0
            const totalNumRunnerlog = 0

            const handleSelectionChange = (selections) => {
                this.selectedRows = []
                this.selectedRows = selections.map(item => item.id);
            }

            const handleSelectionChangeMaintanceRecord = (selections) => {
                this.selectedRowsMaintanceRecord = []
                this.selectedRowsMaintanceRecord = selections.map(item => item.id);
            }

            const handleSelectionChangeRunnerLog = (selections) => {
                this.selectedRowsRunnerLog = []
                this.selectedRowsRunnerLog = selections.map(item => item.id);
            }

            const handleSelectionChangeTestRecord = (selections) => {
                this.selectedRowsTestRecord = []
                this.selectedRowsTestRecord = selections.map(item => item.id);
            }

            const handleModuleCommand = (commandName) => {
                 this.selectedModule = commandName
                 this.selectedModuleName = commandName === '' ? "所有模块" : this.moduleOptionsMap[commandName]
                 this.refreshMaterialFaults()
            }
  
            const handleLevelCommand= (commandName) => {
                this.selectedLevel = commandName
                this.selectedLevelName = commandName === '' ? "所有等级" : ("等级" + commandName)
                this.refreshMaterialFaults()
            }

            const handleLink = (index, row) => {
                this.$router.push({ path: '/flightreport',
                    query: {
                        taskCode: row.taskCode,
                        materialCode: row.sn
                    }
                })
            }

            const handleSizeChange = (val) => {
                this.pageSize = val
                this.currentPage = 1
                this.refreshMaterialFaults();
            }
            const handleCurrentChange = (val) => {
                this.currentPage = val
                this.refreshMaterialFaults();
            }

            const handleTaskTableSizeChange = (val) => {
                this.taskTablePageSize = val
                this.taskTableCurrentPage = 1
                this.refreshMaterialTask();
            }
            const handleTaskTableCurrentChange = (val) => {
                this.taskTableCurrentPage = val
                this.refreshMaterialTask();
            }

            const handleSizeChangeMaintRecord = (val) => {
                this.pageSizeMaintRecord = val
                this.currentPageMaintRecord = 1
                this.refreshMaintanceRecords()
            }

            const handleCurrentChangeMaintRecord = (val) => {
                this.currentPageMaintRecord = val
                this.refreshMaintanceRecords()
            }

            const handleSizeChangeTestRecord = (val) => {
                this.pageSizeTestRecord = val
                this.currentPageTestRecord = 1
                this.refreshTestRecords()
            }
            const handleCurrentChangeTestRecord = (val) => {
                this.currentPageTestRecord = val
                this.refreshTestRecords()
            }
            const handleSizeChangeRunnerlog = (val) => {
                this.pageSizeRunnerlog = val
                this.currentPageRunnerlog = 1
                this.refreshRunnerLogs()
            }
            const handleCurrentChangeRunnerlog = (val) => {
                this.currentPageRunnerlog = val
                this.refreshRunnerLogs()
            }

            return {
                Search,
                Plus,
                Check,
                CaretBottom,
                EditPen,
                CloseBold,
                Calendar,
                config: config,
                uiConfig: {},
                selectedRows: [],
                selectedRowsMaintanceRecord: [],
                selectedRowsRunnerLog: [],
                selectedRowsTestRecord: [],
                currClickNode,
                createdBy,
                createdTime,
                taskStatusMap,
                taskStateOptions,
                projectMap: {},
                companyProjectsMap: {},
                projectSelectOptions: [],
                projectOptions: [],
                orgProjectOptions: [],
                typeOptions: [
                    {
                        value: '',
                        label: '全部类型',
                    },
                    {
                        value: '01',
                        label: 'R3',
                    },
                    {
                        value: '02',
                        label: 'REG300',
                    }
                ],
                typeMap: {
                    '': '-',
                    // '01': '单通道计算机',
                    // '02': '双通道计算机',
                    '01': 'R3',
                    '02': 'REG300'
                },
                lifecyclePhaseOptions: [{
                    value: 1,
                    label: "研发",
                },{
                    value: 2,
                    label: "服役",
                },{
                    value: 3,
                    label: "维修",
                },{
                    value: 4,
                    label: "报废",
                }],
                lifecyclePhaseMap: {
                    0: "全部阶段",
                    1: "研发",
                    2: "服役",
                    3: "维修",
                    4: "报废"
                },
                companyMap,
                companyOptions: [],
                planeDesc,
                offLineView,
                showPlaneEdit,
                planeForm,
                channelOptionsMap,
                barChartCache: null,
                lifecyclePhaseMap: {
                    '0': '-',
                    '1': '研发',
                    '2': '服役',
                    '3':  '维修',
                    '4': '报废'
                },
                testTypeMap: {
                    "0": "RTS测试",
                    "1": "定期测试"
                },
                channelMap: {
                    "0": "COM",
                    "1": "MON"
                },
                testResultMap: {
                    "0": "通过",
                    "1": "不通过",
                    "2": "不通过",
                    "3": "不通过"
                },
                logTypeMap: {
                    "0": "系统日志",
                    "1": "异常复位日志",
                    "2": "运行时分析日志"
                },
                sourceFromMap: {
                    0: '注册',
                    1: '系统'
                },
                timeUnits: {
                    year: '年',
                    month: '月',
                    day: '日'
                },
                phaseColorMap: {
                    0: "#e2ffff",
                    1: "#e2ffff",
                    2: "#e6e8f0",
                    3: "#fffce2",
                    4: "#d1dbe5"
                },
                phaseTextColorMap: {
                    0: "#88dee7",
                    1: "#88dee7",
                    2: "rgb(163 170 197)",
                    3: "rgb(231 222 144)",
                    4: "#a2c1df"
                },
                phaseNameMap: {
                    1: "研发",
                    2: "服役",
                    3: "维修",
                    4: "报废"
                },
                datepPhaseNameMap: {},
                flightPhaseMap: {
                    0: 'Disarmed，锁定',
                    1: 'Idle，怠速',
                    2: 'AutoGroundCheck，自动航前检查',
                    3: 'ManualGroundCheck，手动航前检查',
                    4: 'MultiCopter，多旋翼',
                    5: 'Transition，转换',
                    6: 'FixedWing，固定翼',
                    7: 'Retransition，后向转换'
                },
                formLabelWidth,
                radioChartType: "fault",
                statePhaseOptionsMap,
                faultTypeOptionsMap,
                R3faultTypeOptions,
                REG300faultTypeOptions,
                faultTypeOptions,
                moduleOptionsMap,
                moduleOptions,
                levelOptions,
                statePhaseOptions,
                channelOptions,
                materialBarPhases: [],
                selectedTimeRange,
                selectedLevel,
                selectedLevelName,
                selectedModuleName,
                selectedChannelName,
                selectedStatePhaseName,
                selectedTaskStateName,
                selectedTaskState: "",
                selectedFaultTypeName,
                selectedMaintUserName,
                selectedMaintUser: "",
                maintUserOptions: [{
                    id: "",
                    name: "所有维护人员"
                }],
                maintUsersMap: {"": "所有维护人员"},
                selectedModule,
                materialDetail,
                tableForm,
                selectedPlane,
                tableData,
                tableDataTestRecord: [],
                tableDataRunLog: [],
                maintanceTableData: [],
                taskTableData,
                totalNum,
                totalNumRunnerlog,
                totalNumMaintRecord,
                totalNumTestRecord,
                pageSize,
                currentPage,
                paginationSize: 'small',
                pageSizeMaintRecord,                
                currentPageMaintRecord,
                pageSizeRunnerlog,
                currentPageRunnerlog,
                pageSizeTestRecord,
                currentPageTestRecord,
                taskTableCurrentPage,
                taskTablePageSize,
                taskTableTotalNum,
                currMaterialId,
                searchName,
                treeData,
                isSingleChart: true,
                showLogVisible: false,
                showTestRecordVisible: false,
                showEdit: false,
                hideEdit: true,
                editingPlaneId,
                activeTabName,
                activeChannleTabName: "allData",
                dataChannelSelected: "allData",
                planeImage,
                editingMaterialId: "",
                materialform: reactive({
                    name: '',
                    email: '',
                    company: '',
                    type: '',
                    materialCode: ''
                }),
                faultTypeMap:{
                    0: '锁存故障',
                    1: '常规故障'
                },
                R3FaultTypeMap:{
                    0: '永久故障',
                    1: '实时故障'
                },
                statusMap,
                barSeriesName: "",
                errorStatusMap,
                pageWH: {},
                handleSelectionChange,
                handleSelectionChangeMaintanceRecord,
                handleSelectionChangeRunnerLog,
                handleSelectionChangeTestRecord,
                handleSizeChange,
                handleCurrentChange,
                handleTaskTableSizeChange,
                handleTaskTableCurrentChange,
                handleSizeChangeMaintRecord,
                handleCurrentChangeMaintRecord,
                handleCurrentChangeRunnerlog,
                handleSizeChangeRunnerlog,
                handleSizeChangeTestRecord,
                handleCurrentChangeTestRecord,
                handleClick,
                handleNodeClick,
                handleLink,
                handleModuleCommand,
                handleLevelCommand,
                logInfo: {
                    sn: '',
                    type: '',
                    size: '',
                    time: ''
                },
                logContent: '',
                testInfo: {
                    sn: '',
                    type: '',
                    user: '',
                    time: '',
                    testChannel: '',
                    list: []
                }
            };
        },
        methods: {
            gotoEdit() {
                this.showEdit = true
                this.hideEdit = false
            },
            cancelEdit() {
                this.showEdit = false
                this.hideEdit = true

                setTimeout(() => {
                    this.renderChart(this.barChartCache.data, this.barChartCache.div, this.barChartCache.unit, this.isSingleChart, this.barChartCache.title, this.barSeriesName)
                }, 500);
            },
            updateMaterialById() {
                this.$store.dispatch('material/updatematerial', {
                    materialId: this.editingMaterialId,
                    companyId: this.materialform.company,
                    planeId: this.materialform.plane,
                    project: this.materialform.projectId,
                    lifecyclePhase: this.materialform.lifecyclePhase,
                    type: this.materialform.type
                }).then(() => {
                    this.showEdit = false
                    this.hideEdit = true
                    ElMessage.success('修改成功!')
                    this.refreshMaterialInfo(false)
                }).catch((e) => {
                    ElMessage.error('修改失败!')
                    this.loading = false
                })

                setTimeout(() => {
                    this.renderChart(this.barChartCache.data, this.barChartCache.div, this.barChartCache.unit, this.isSingleChart, this.barChartCache.title, this.barSeriesName)
                }, 500);
            },
            formatTimestampToDate (timestamp) {
                return moment(timestamp).utcOffset('+0800').format('YYYY-MM-DD HH:mm:ss')
            },
            async fetchLogContent(row) {
                this.logInfo.sn = this.materialDetail.materialCode + '-' + row.channel;
                this.logInfo.type = row.logType;
                this.logInfo.size = row.size;
                this.logInfo.time = row.time + "("+ row.powerTime +")";
                this.logInfo.fileName = row.fileName
                try {
                    // this.$axios.get('/api/SN00000002/sd/0/profile.json').then(({data}) =>{ this.logContent = data; });
                } catch (error) {
                    console.error('Error loading log content:', error);
                }


                this.$store.dispatch('materialrecord/genRunnerLogContent', {
                    url: row.url
                }).then((data) => {
                    this.loading = false
                    this.logContent =  data.data
                }).catch((e) => {
                    this.loading = false
                })
            },
            handleLinkToTicket(index, row) {
                const cloudUrl = 'https://devops.aliyun.com/projex/project/94c6027d24c479dd22721c3a0c/bug#openWorkitemIdentifier=' + row.yunxiaoTicket;   //  row.ticket
                window.open(cloudUrl, '_blank');
            },
            handleLinkToEdit () {
                this.$router.push({ path: '/recordeditmaterial',
                    // name: 'materialrecordview',
                    params: {
                        materialId: ""
                    },
                    query: {
                        // materialId: row.id,
                        materialCode: this.materialDetail.materialCode
                    }
                })

                /* this.$router.push({name:"materialrecordview", params:{name:'nameValue', code:10011} });  */
            },
            exportLogInfo() {
                // 导出日志信息的逻辑
                const filename = this.logInfo.fileName;
                const url = window.URL.createObjectURL(new Blob([this.logContent]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            },
            handleLogView(index, row) {
                this.showLogVisible = true 
                this.fetchLogContent(row)               
            },
            handleDataChannelClick(tab, event) {
                this.activeChannleTabName = tab.props.name
                this.chartRadioChange("fault")
            },
            handleTestItemView(index, row) {
                this.showTestRecordVisible = true 
                for (let inx in row.testItems) {
                    row.testItems[inx].testItemResult = this.testResultMap[row.testItems[inx].testItemResult]
                }
                this.testInfo = {
                    sn: this.materialDetail.materialCode,
                    type: row.testType,
                    userId: row.testUserId,
                    userName: row.testUserName,
                    time: row.testTime,
                    testChannel: row.testChannel,
                    list: row.testItems,
                    testResult: row.testResult
                }
            },
            handleStatePhaseCommand(commandName) {
                 this.selectedStatePhase = commandName
                 this.selectedStatePhaseName = commandName === '' ? "全部周期" : this.statePhaseOptionsMap[commandName]
                 this.refreshTableData(this.activeTabName)
            },
            handleTaskStateCommand(commandName) {
                 this.selectedTaskState = commandName
                 this.selectedTaskStateName = commandName === '' ? "全部状态" : this.taskStatusMap[commandName]
                 this.refreshTableData(this.activeTabName)
            },
            handleChannelCommand(commandName) {
                 this.selectedChannel = commandName
                 this.selectedChannelName = commandName === '' ? "全部通道" : this.channelOptionsMap[commandName]
                 this.refreshTableData(this.activeTabName)
            },
            handleFaultTypCommand(commandName) {
                 this.selectedFaultType = commandName
                 this.selectedFaultTypeName = commandName === '' ? "全部类型" : this.faultTypeOptionsMap[commandName]
                 this.refreshTableData(this.activeTabName)
            },
            handleMaintUserCommand(commandName) {
                 this.selectedMaintUser = commandName
                 this.selectedMaintUserName = commandName === '' ? "所有维护人员" : this.maintUsersMap[commandName]
                 this.refreshTableData(this.activeTabName)
            },
            timeDateChanged(value) {
                this.refreshTableData(this.activeTabName)
            },
            refreshTableData(currTab) {
                if(currTab=='five') {
                    this.refreshRunnerLogs()  
                }
                if(currTab=='four') {
                    this.refreshMaintanceRecords()
                }
                if(currTab=='third') {
                    this.refreshTestRecords()
                }
                if(currTab=='first') {
                    this.refreshMaterialTask()
                }
                if(currTab=='second') {
                    this.refreshMaterialFaults()
                }
            },
            refreshRunnerLogs() {
                this.$store.dispatch('materialrecord/queryRunnerLogs', {
                    pageIndex: this.currentPageRunnerlog,
                    pageSize: this.pageSizeRunnerlog,
                    searchName: this.searchName,
                    channelType: this.selectedChannel,
                    lifecyclePhase: this.selectedStatePhase == '' ? null : parseInt(this.selectedStatePhase),
                    startTime: (this.selectedTimeRange != null && this.selectedTimeRange.length > 1 ) ? this.selectedTimeRange[0] : null,
                    endTime: (this.selectedTimeRange != null && this.selectedTimeRange.length > 1 ) ? this.selectedTimeRange[1] : null,
                    sn: this.materialDetail.materialCode
                }).then((data) => {
                    this.loading = false
                    this.tableDataRunLog = data.list
                    for (let inx in this.tableDataRunLog) {
                        this.tableDataRunLog[inx].logType = this.logTypeMap["" + this.tableDataRunLog[inx].type]
                        this.tableDataRunLog[inx].channel = this.channelMap["" + this.tableDataRunLog[inx].channelType]
                        this.tableDataRunLog[inx].powerTime = "第" + this.tableDataRunLog[inx].powerOnCnt + "次上电"
                        this.tableDataRunLog[inx].size = ((this.tableDataRunLog[inx].fileSize/1024/1024).toFixed(3) == '0.000' ? (this.tableDataRunLog[inx].fileSize/1024/1024 == 0 ? '0' : '0.001') : (this.tableDataRunLog[inx].fileSize/1024/1024).toFixed(3)) + "MB"
                        this.tableDataRunLog[inx].time = this.formatTimestampToDate(this.tableDataRunLog[inx].dateTime)
                        this.tableDataRunLog[inx].phase = this.lifecyclePhaseMap["" + this.tableDataRunLog[inx].lifecyclePhase]
                    }
                    this.totalNumRunnerlog = data.total
                    this.currentPageRunnerlog = data.pageIndex
                }).catch((e) => {
                    this.loading = false
                })
            },
            refreshMaintanceRecords() {
                this.$store.dispatch('materialrecord/queryMaintanceRecords', {
                    pageIndex: this.currentPageMaintRecord,
                    pageSize: this.pageSizeMaintRecord,
                    searchName: this.searchName,
                    channelType: this.selectedChannel == '' ? null : parseInt(this.selectedChannel),
                    lifecyclePhase: this.selectedStatePhase == '' ? null : parseInt(this.selectedStatePhase),
                    startTime: (this.selectedTimeRange != null && this.selectedTimeRange.length > 1 ) ? this.selectedTimeRange[0] : null,
                    endTime: (this.selectedTimeRange != null && this.selectedTimeRange.length > 1 ) ? this.selectedTimeRange[1] : null,
                    sn: this.materialDetail.materialCode,
                    maintUserId: this.selectedMaintUser
                }).then((data) => {
                    this.loading = false
                    this.maintanceTableData = data.list
                    for (let inx in this.maintanceTableData) {
                        this.maintanceTableData[inx].testResult = this.testResultMap["" + this.maintanceTableData[inx].testResult]
                        this.maintanceTableData[inx].phase = this.lifecyclePhaseMap["" + this.maintanceTableData[inx].lifecyclePhase]
                        this.maintanceTableData[inx].channel = this.channelMap["" + this.maintanceTableData[inx].channelType]
                        this.maintanceTableData[inx].clearFaults = this.maintanceTableData[inx].faults + "条故障"
                        this.maintanceTableData[inx].maintUserId = this.maintanceTableData[inx].operatorId
                        this.maintanceTableData[inx].maintUserName = this.maintanceTableData[inx].operatorName
                        this.maintanceTableData[inx].maintTime = this.formatTimestampToDate(this.maintanceTableData[inx].maintTime * 1000)
                        
                        let faults = []
                        for(let indx in this.maintanceTableData[inx].faultList) {
                            this.maintanceTableData[inx].faultList[indx].occurTime = this.formatTimestampToDate(this.maintanceTableData[inx].faultList[indx].occurTime * 1000)
                            this.maintanceTableData[inx].faultList[indx].channel = this.channelMap[this.maintanceTableData[inx].faultList[indx].channelType]
                            this.maintanceTableData[inx].faultList[indx].flightPhase = this.flightPhaseMap[this.maintanceTableData[inx].faultList[indx].flightPhase]
                            this.maintanceTableData[inx].faultList[indx].description = this.maintanceTableData[inx].faultList[indx].description
                            this.maintanceTableData[inx].faultList[indx].faultName =  this.maintanceTableData[inx].faultList[indx].faultName
                            faults.push(this.maintanceTableData[inx].faultList[indx])
                        }
                        this.maintanceTableData[inx].faults = faults
                    }
                    this.totalNumMaintRecord = data.total
                    this.currentPageMaintRecord = data.pageIndex 
                }).catch((e) => {
                    this.loading = false
                })
            },
            refreshTestRecords() {
                this.$store.dispatch('materialrecord/queryTestRecords', {
                    pageIndex: this.currentPageTestRecord,
                    pageSize: this.pageSizeTestRecord,
                    searchName: this.searchName,
                    channelType: this.selectedChannel,
                    lifecyclePhase: this.selectedStatePhase == '' ? null : parseInt(this.selectedStatePhase),
                    startTime: (this.selectedTimeRange != null && this.selectedTimeRange.length > 1 ) ? this.selectedTimeRange[0] : null,
                    endTime: (this.selectedTimeRange != null && this.selectedTimeRange.length > 1 ) ? this.selectedTimeRange[1] : null,
                    sn: this.materialDetail.materialCode
                }).then((data) => {
                    this.loading = false
                    this.tableDataTestRecord = data.list
                    for (let inx in this.tableDataTestRecord) {
                        this.tableDataTestRecord[inx].testResult = this.testResultMap["" + this.tableDataTestRecord[inx].testResult]
                        this.tableDataTestRecord[inx].phase = this.statePhaseOptionsMap["" + this.tableDataTestRecord[inx].lifecyclePhase]
                        this.tableDataTestRecord[inx].testChannel = this.channelMap["" + this.tableDataTestRecord[inx].channelType]
                        this.tableDataTestRecord[inx].testResult = this.tableDataTestRecord[inx].testResult  //  this.testResultMap["" + this.tableData[inx].testResult]
                        this.tableDataTestRecord[inx].testType = this.testTypeMap["" + this.tableDataTestRecord[inx].testType]
                        this.tableDataTestRecord[inx].testTime = this.formatTimestampToDate(this.tableDataTestRecord[inx].testTime*1000)
                        this.tableDataTestRecord[inx].testUserId = this.tableDataTestRecord[inx].operatorId
                        this.tableDataTestRecord[inx].testUserName = this.tableDataTestRecord[inx].operatorName
                    }
                    this.totalNumTestRecord = data.total
                    this.currentPageTestRecord = data.pageIndex
                }).catch((e) => {
                    this.loading = false
                })
            },
            exportTestRecords () {
                // 导出当前页的已有数据
                this.$store.dispatch('materialrecord/exportTestRecords', {
                    // 转接一下
                    pageIndex: this.currentPage,
                    pageSize: this.pageSize,
                    searchName: this.searchName,
                    channelType: this.selectedChannel,
                    lifecyclePhase: this.selectedStatePhase == '' ? null : parseInt(this.selectedStatePhase),
                    startTime: (this.selectedTimeRange != null && this.selectedTimeRange.length > 1 ) ? this.selectedTimeRange[0] : null,
                    endTime: (this.selectedTimeRange != null && this.selectedTimeRange.length > 1 ) ? this.selectedTimeRange[1] : null,
                    sn: this.materialDetail.materialCode,
                    recordIds: this.selectedRowsTestRecord
                }).then((response) => {
                        const blob = new Blob([response.data]);
                        const downloadUrl = window.URL.createObjectURL(blob);
                        const link = document.createElement('a');
                        link.href = downloadUrl;
                        link.download = "测试记录-" + this.materialDetail.materialCode + ".xlsx";
                        link.click();
                        window.URL.revokeObjectURL(downloadUrl);
                }).catch((e) => {
                    this.loading = false
                })
            },
            exportMaintanceRecords () {
                // 导出当前页的已有数据
                this.$store.dispatch('materialrecord/exportMaintanceRecords', {
                    // 转接一下
                    pageIndex: this.currentPageMaintRecord,
                    pageSize: this.pageSizeMaintRecord,
                    searchName: this.searchName,
                    channelType: this.selectedChannel,
                    lifecyclePhase: this.selectedStatePhase == '' ? null : parseInt(this.selectedStatePhase),
                    startTime: (this.selectedTimeRange != null && this.selectedTimeRange.length > 1 ) ? this.selectedTimeRange[0] : null,
                    endTime: (this.selectedTimeRange != null && this.selectedTimeRange.length > 1 ) ? this.selectedTimeRange[1] : null,
                    sn: this.materialDetail.materialCode,
                    recordIds: this.selectedRowsMaintanceRecord
                }).then((response) => {
                        const blob = new Blob([response.data]);
                        const downloadUrl = window.URL.createObjectURL(blob);
                        const link = document.createElement('a');
                        link.href = downloadUrl;
                        link.download = "维护记录-" + this.materialDetail.materialCode + ".xlsx";
                        link.click();
                        window.URL.revokeObjectURL(downloadUrl);
                }).catch((e) => {
                    this.loading = false
                })
            },
            exportRunnerLogs () {
                // 导出当前页的已有数据
                this.$store.dispatch('materialrecord/exportRunnerLogs', {
                    // 转接一下
                    pageIndex: this.currentPage,
                    pageSize: this.pageSize,
                    searchName: this.searchName,
                    channelType: this.selectedChannel,
                    lifecyclePhase: this.selectedStatePhase == '' ? null : parseInt(this.selectedStatePhase),
                    startTime: (this.selectedTimeRange != null && this.selectedTimeRange.length > 1 ) ? this.selectedTimeRange[0] : null,
                    endTime: (this.selectedTimeRange != null && this.selectedTimeRange.length > 1 ) ? this.selectedTimeRange[1] : null,
                    sn: this.materialDetail.materialCode,
                    recordIds: this.selectedRowsRunnerLog
                }).then((response) => {
                        const blob = new Blob([response.data]);
                        const downloadUrl = window.URL.createObjectURL(blob);
                        const link = document.createElement('a');
                        link.href = downloadUrl;
                        link.download = "运行日志-" + this.materialDetail.materialCode + ".zip";
                        link.click();
                        window.URL.revokeObjectURL(downloadUrl);
                }).catch((e) => {
                    this.loading = false
                })
            },
            exportMaterialFaults () {
                // 导出当前页的已有数据
                this.$store.dispatch('materialrecord/exportMaterialFaultlist', {
                    // 转接一下
                    pageIndex: this.currentPage,
                    pageSize: this.pageSize,
                    searchName: this.searchName,
                    channelType: this.selectedChannel == '' ? null : this.selectedChannel,
                    type: this.selectedFaultType == '' ? null : this.selectedFaultType,
                    lifecyclePhase: this.selectedStatePhase == '' ? null : parseInt(this.selectedStatePhase),
                    module: this.selectedModule == '' ? null : this.selectedModule,
                    startTime: (this.selectedTimeRange != null && this.selectedTimeRange.length > 1 ) ? this.selectedTimeRange[0] : "",
                    endTime: (this.selectedTimeRange != null && this.selectedTimeRange.length > 1 ) ? this.selectedTimeRange[1] : "",
                    materialCode: this.materialDetail.materialCode,
                    recordIds: this.selectedRows
                }).then((response) => {
                        const blob = new Blob([response.data]);
                        const downloadUrl = window.URL.createObjectURL(blob);
                        const link = document.createElement('a');
                        link.href = downloadUrl;
                        link.download = "故障码-" + this.materialDetail.materialCode + ".xlsx";
                        link.click();
                        window.URL.revokeObjectURL(downloadUrl);
                }).catch((e) => {
                    this.loading = false
                })
            },
            refreshMaterialFaults () {
                console.log('query material OBD Exceptions')
                this.$store.dispatch('materialrecord/queryMaterialFaultlist', {
                    pageIndex: this.currentPage,
                    pageSize: this.pageSize,
                    searchName: this.searchName,
                    channelType: this.selectedChannel == '' ? null : this.selectedChannel,
                    type: this.selectedFaultType == '' ? null : this.selectedFaultType,
                    lifecyclePhase: this.selectedStatePhase == '' ? null : parseInt(this.selectedStatePhase),
                    module: this.selectedModule == '' ? null : this.selectedModule,
                    startTime: (this.selectedTimeRange != null && this.selectedTimeRange.length > 1 ) ? this.selectedTimeRange[0] : "",
                    endTime: (this.selectedTimeRange != null && this.selectedTimeRange.length > 1 ) ? this.selectedTimeRange[1] : "",
                    materialCode: this.materialDetail.materialCode
                }).then((data) => {
                    this.loading = false
                    this.tableData = data.list
                    for (let inx in this.tableData) {
                        this.tableData[inx].channel = this.channelMap[this.tableData[inx].channelType]
                        this.tableData[inx].module = this.moduleOptionsMap[this.tableData[inx].moduleId]
                        this.tableData[inx].status = this.errorStatusMap[this.tableData[inx].status]
                        this.tableData[inx].clearTime = this.tableData[inx].clearTime == 0? "-" : this.formatTimestampToDate(this.tableData[inx].clearTime * 1000)
                        this.tableData[inx].occurTimeStr = this.formatTimestampToDate(this.tableData[inx].occurTime * 1000) + " (第" + this.tableData[inx].occurPoweredTimes + "次上电)"
                        this.tableData[inx].flightPhase = this.flightPhaseMap[this.tableData[inx].flightPhase]
                        this.tableData[inx].type = this.typeMap[this.materialDetail.type] == 'R3' ? this.R3FaultTypeMap[this.tableData[inx].type] : this.faultTypeMap[this.tableData[inx].type]
                        this.tableData[inx].phase = this.lifecyclePhaseMap[this.tableData[inx].lifecyclePhase]
                    }
                    this.totalNum = data.total
                    this.currentPage = data.pageIndex
                }).catch((e) => {
                    this.loading = false
                })
            },
            exportFlightRecord () {
                // 导出当前页的已有数据
                this.$store.dispatch('materialrecord/exportMaterialFlightlist', {
                    // 转接一下
                    pageIndex: this.currentPage,
                    pageSize: this.pageSize,
                    searchName: this.searchName,
                    status: this.selectedTaskState,
                    lifecyclePhase: this.selectedStatePhase == '' ? null : parseInt(this.selectedStatePhase),
                    startTime: (this.selectedTimeRange != null && this.selectedTimeRange.length > 1 ) ? this.selectedTimeRange[0] : "",
                    endTime: (this.selectedTimeRange != null && this.selectedTimeRange.length > 1 ) ? this.selectedTimeRange[1] : "",
                    materialCode: this.materialDetail.materialCode,
                    recordIds: this.selectedRows
                }).then((response) => {
                        const blob = new Blob([response.data]);
                        const downloadUrl = window.URL.createObjectURL(blob);
                        const link = document.createElement('a');
                        link.href = downloadUrl;
                        link.download = "飞行记录-" + this.materialDetail.materialCode + ".xlsx";
                        link.click();
                        window.URL.revokeObjectURL(downloadUrl);
                }).catch((e) => {
                    this.loading = false
                })
            },
            refreshMaintanceUsers() {
                this.$store.dispatch('materialrecord/queryMaintanceUsers', {
                    materialCode: this.materialDetail.materialCode
                }).then((data) => {
                    for(let inx in data) {
                        this.maintUserOptions.push({
                            id: data[inx].id,
                            name: data[inx].name,
                        })
  
                        this.maintUsersMap[data[inx].id] = data[inx].name
                    }
                }).catch((e) => {
                    this.loading = false
                })
            },
            refreshMaterialTask () {
                this.$store.dispatch('materialrecord/querymaterialtasks', {
                    lifecyclePhase: this.selectedStatePhase == '' ? null : parseInt(this.selectedStatePhase),
                    status: this.selectedTaskState,
                    pageIndex: this.taskTableCurrentPage,
                    pageSize: this.taskTablePageSize,
                    searchName: this.searchName,
                    materialCode: this.materialDetail.materialCode,
                    startTime: (this.selectedTimeRange != null && this.selectedTimeRange.length > 1 ) ? this.selectedTimeRange[0] : "",
                    endTime: (this.selectedTimeRange != null && this.selectedTimeRange.length > 1 ) ? this.selectedTimeRange[1] : ""
                }).then((data) => {
                    this.loading = false
                    this.taskTableData = data.list
                    this.taskTableCurrentPage = data.pageIndex
                    this.taskTableTotalNum = data.total
                    for(let inx in this.taskTableData) {
                        this.taskTableData[inx].status = this.taskStatusMap[this.taskTableData[inx].status]
                        this.taskTableData[inx].totalTimeCost = this.buildFormatTime(this.taskTableData[inx].totalTime)
                        this.taskTableData[inx].totalDistanceKM = this.taskTableData[inx].totalDistance/1000
                        this.taskTableData[inx].phase = this.lifecyclePhaseMap[this.taskTableData[inx].lifecyclePhase]
                    }
                }).catch((e) => {
                    this.loading = false
                })
            },
            buildFormatTime(seconds) {
                let hours = Math.floor(seconds / 3600);
                let minutes = Math.floor((seconds % 3600) / 60);
                let remainingSeconds = seconds % 60;
                let formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
                return formattedTime
            },
            refreshMaterialInfo(isInit) {
                console.log('query material')
                this.$store.dispatch('material/queryMaterialInfoByCode', {
                    materialCode: this.materialDetail.materialCode
                }).then((data) => {
                    this.loading = false
                    
                    this.faultTypeOptions = data.type == "01" ? this.R3faultTypeOptions : this.REG300faultTypeOptions
                    this.faultTypeOptionsMap = data.type == "01" ? { '': '全部类型', '0': '永久故障', '1': '实时故障' } : {'': '全部类型', '0': '锁存故障', '1': '常规故障' }

                    this.materialDetail.extendDetail = data.extendDetail
                    this.materialDetail.createdTime = (data.createdTime == null) ? "" : data.createdTime.substring(0, 10)
                    this.materialDetail.type = data.type

                    let deviceTypeConfig = this.config.device.type[this.config.device.type[data.type]]   // 根据类型获取实际的配置
                    const picPath = deviceTypeConfig == null ? "R3_m.png" : deviceTypeConfig.pic["pic_m"]  // 根据类型获取实际的配置
                    // 使用 Webpack 的 require 来解析路径
                    this.planeImage = require(`@/icons/material/${picPath}`);
                    
                    this.materialDetail.typeName = this.typeMap[data.type] == null ? "-" : this.typeMap[data.type]
                    this.materialDetail.companeyId = data.companyId
                    this.materialDetail.company = (data.companyName == null || data.companyName == "") ? "-" : data.companyName
                    this.materialDetail.phaseStateName = this.lifecyclePhaseMap[data.lifecyclePhase] == null ? '-' : this.lifecyclePhaseMap[data.lifecyclePhase]
                    this.materialDetail.project = (data.projectName == null || data.projectName == "") ? "-" : data.projectName
                    this.materialDetail.stateKey = data.status
                    this.materialDetail.state = this.statusMap[data.status] ? this.statusMap[data.status] : "-"
                    this.materialDetail.sourceFrom = this.sourceFromMap[data.sourceFrom] ? this.sourceFromMap[data.sourceFrom] :  "-"

                    this.materialDetail.channelView  = deviceTypeConfig == null ? "single" : deviceTypeConfig.channelView
                    if(data.deviceChannelConfigurations != null && data.deviceChannelConfigurations.length > 0 && this.materialDetail.channelView == "double") { 
                        for(let inx in data.deviceChannelConfigurations) {
                            if (data.deviceChannelConfigurations[inx].channelType == 0) {
                                this.materialDetail.com = {
                                    'powerTimes': data.deviceChannelConfigurations[inx].powerOnCnt,
                                    'runTime':  (data.deviceChannelConfigurations[inx].powerOnDuration/3600).toFixed(1),
                                    'dkm': data.deviceChannelConfigurations[inx].dkmVersion,
                                    'vip': data.deviceChannelConfigurations[inx].vipVersion,
                                    'fpga': data.deviceChannelConfigurations[inx].fpgaVersion,
                                    'firmware': data.deviceChannelConfigurations[inx].hwVersion,
                                    'type': 'COM通道'
                                }
                            } else {
                                this.materialDetail.mon = {
                                    'powerTimes': data.deviceChannelConfigurations[inx].powerOnCnt,
                                    'runTime':  (data.deviceChannelConfigurations[inx].powerOnDuration/3600).toFixed(1),
                                    'dkm': data.deviceChannelConfigurations[inx].dkmVersion,
                                    'vip': data.deviceChannelConfigurations[inx].vipVersion,
                                    'fpga': data.deviceChannelConfigurations[inx].fpgaVersion,
                                    'firmware': data.deviceChannelConfigurations[inx].hwVersion,
                                    'type': 'MON通道'
                                }
                            }
                        }
                    } else {
                        // this.materialDetail.channelView = "single"
                        if(data.deviceChannelConfigurations != null && data.deviceChannelConfigurations.length == 1) {
                            this.materialDetail.single = {
                                'powerTimes': data.deviceChannelConfigurations[0].powerOnCnt,
                                'runTime':  (data.deviceChannelConfigurations[0].powerOnDuration/3600).toFixed(1),
                                'dkm': data.deviceChannelConfigurations[0].dkmVersion,
                                'vip': data.deviceChannelConfigurations[0].vipVersion,
                                'fpga': data.deviceChannelConfigurations[0].fpgaVersion,
                                'firmware': data.deviceChannelConfigurations[0].hwVersion,
                                'type': ''
                            }
                        }
                    }

                    this.genBarChartLifecyclePhase(data.extendDetail)
                    // 再查趋势数据
                    this.queryFaultOverviewStatistics(2)

                    this.editingMaterialId = data.id
                    this.projectSelectOptions = this.companyProjectsMap[data.companyId] != null ? this.companyProjectsMap[data.companyId] : [{  value: "",  label: "全部项目" }]
                    this.materialform.company = data.companyId
                    this.materialform.type = data.type
                    this.materialform.materialCode= this.materialDetail.materialCode
                    this.materialform.createdTime = data.createdTime
                    this.materialform.bindTime = data.bindTime
                    this.materialform.lifecyclePhase = data.lifecyclePhase
                    this.materialform.projectId = data.projectId

                    if(isInit) {
                        this.refreshMaterialFaults()
                        this.refreshMaterialTask()
                    }
                }).catch((e) => {
                    this.loading = false
                })
            },
            queryFaultOverviewStatistics(channelType) {
                this.dialogFormVisible = false
                this.$store.dispatch('faultdata/queryfaultoverviewstatistics', {
                    companyId: null,
                    timeBy: null,
                    timeNo: null,
                    sn: this.materialDetail.materialCode,
                    channelType: channelType
                }).then((data) => {
                    this.dialogFormVisible = false
                    this.loading = false
                    if(data != null) {
                        for(let indx in data) {
                            if(data[indx].timeDatas != null) {
                                for (let inx in data[indx].timeDatas) {
                                    data[indx].timeDatas[inx].val = data[indx].timeDatas[inx].val < 0 ? null : data[indx].timeDatas[inx].val
                                }
                            }
                        }
                    }

                    if(this.activeChannleTabName == 'allData' && this.materialDetail.channelView == 'double') {
                        let legend = {}
                        legend['COM 通道'] = 'COM 通道：' + this.materialDetail.extendDetail["totalComFault"] + '个'
                        legend['MON 通道'] = 'MON 通道：' + this.materialDetail.extendDetail["totalMonFault"] + '个'
                        this.barChartCache = {
                            'data': data,
                            'div': 'flightPanel',
                            'unit': "个",
                            'title': '{leftBox|总的故障数}\n{leftBoxLine2|'+ this.materialDetail.extendDetail["totalFault"] + '个}'
                        }
                        this.renderChart(data, 'flightPanel', "个", false, this.barChartCache.title, "", legend)
                    } else {
                        let seriesName = channelType == 0 ? "COM 通道" : "MON 通道"
                        if(this.materialDetail.channelView == "single") {
                            seriesName = "故障数"
                            this.barChartCache = {
                                'data': data[0],
                                'div': 'flightPanel',
                                'unit': "个",
                                'title': '{leftBox|总的故障数}\n{leftBoxLine2|'+ this.materialDetail.extendDetail["totalFault"] + '个}'
                            }
                        } else {
                            this.barChartCache = {
                                'data': data[0],
                                'div': 'flightPanel',
                                'unit': "个",
                                'title': '{leftBox|总的故障数}\n{leftBoxLine2|' +  (channelType == 0 ? this.materialDetail.extendDetail["totalComFault"] : this.materialDetail.extendDetail["totalMonFault"]) + '个}'
                            }
                        }
                        this.renderChart(data[0], 'flightPanel', "个", true, this.barChartCache.title, seriesName, [])
                    }
                    
                }).catch((e) => {
                    this.loading = false
                })
            },
            queryDeviceOnPowerStatistics(channelType) {
                this.dialogFormVisible = false
                this.$store.dispatch('devicedata/queryonpowerstatistics', {
                    companyId: null,
                    timeBy: null,
                    timeNo: null,
                    sn: this.materialDetail.materialCode,
                    channelType: channelType
                }).then((data) => {
                    this.dialogFormVisible = false
                    this.loading = false
                    if(data != null) {
                        for(let indx in data) {
                            if(data[indx].timeDatas != null) {
                                for (let inx in data[indx].timeDatas) {
                                    data[indx].timeDatas[inx].val = data[indx].timeDatas[inx].val < 0 ? null : data[indx].timeDatas[inx].val
                                }
                            }
                        }
                    }
                    
                    if(this.activeChannleTabName == 'allData' && this.materialDetail.channelView == 'double') {
                        let legend = {}
                        legend['COM 通道'] = 'COM 通道：' + this.materialDetail.com.powerTimes +'次'
                        legend['MON 通道'] = 'MON 通道：' + this.materialDetail.mon.powerTimes + '次'
                        this.barChartCache = {
                            'data': data,
                            'div': 'flightPanel',
                            'unit': "次",
                            'title': '{leftBox|总的上电次数}\n{leftBoxLine2|'+ this.materialDetail.com.powerTimes +'次}'
                        }

                        this.renderChart(data, 'flightPanel', "次", false, this.barChartCache.title, "", legend)
                    } else {
                        let seriesName = channelType == 0 ? "COM 通道" : "MON 通道"
                        if(this.materialDetail.channelView == "single") {
                            seriesName = "上电次数"
                            this.barChartCache = {
                                'data': data[0],
                                'div': 'flightPanel',
                                'unit': "次",
                                'title': '{leftBox|总的上电次数}\n{leftBoxLine2|'+ this.materialDetail.com.powerTimes +'次}'
                            }
                        } else {
                            this.barChartCache = {
                                'data': data[0],
                                'div': 'flightPanel',
                                'unit': "次",
                                'title': '{leftBox|总的上电次数}\n{leftBoxLine2|' + (channelType == 0 ? this.materialDetail.com.powerTimes : this.materialDetail.mon.powerTimes) + '次}'
                            }
                        }

                        this.renderChart(data[0], 'flightPanel', "次", true, this.barChartCache.title, seriesName, [])
                    }
                }).catch((e) => {
                    this.loading = false
                })
            },
            queryDeviceRuntimeStatistics(channelType) {
                this.dialogFormVisible = false
                this.$store.dispatch('devicedata/queryruntimestatistics', {
                    companyId: null,
                    timeBy: null,
                    timeNo: null,
                    sn: this.materialDetail.materialCode,
                    channelType: channelType
                }).then((data) => {
                    this.dialogFormVisible = false
                    this.loading = false
                    if(data != null) {
                        for(let indx in data) {
                            if(data[indx].timeDatas != null) {
                                for (let inx in data[indx].timeDatas) {
                                    data[indx].timeDatas[inx].val = data[indx].timeDatas[inx].val < 0 ? null : data[indx].timeDatas[inx].val
                                }
                            }
                        }
                    }
                    
                    if(this.activeChannleTabName == 'allData' && this.materialDetail.channelView == 'double') {
                        let legend = {}
                        legend['COM 通道'] = 'COM 通道：' + this.materialDetail.com.runTime +'小时'
                        legend['MON 通道'] = 'MON 通道：' + this.materialDetail.mon.runTime + '小时'
                        this.barChartCache = {
                            'data': data,
                            'div': 'flightPanel',
                            'unit': "小时",
                            'title': '{leftBox|总的运行时长}\n{leftBoxLine2|'+ this.materialDetail.com.runTime +'小时}'
                        }
                        this.renderChart(data, 'flightPanel', "次", false, this.barChartCache.title, "", legend)
                    } else {
                        this.barChartCache = {
                            'data': data[0],
                            'div': 'flightPanel',
                            'unit': "小时",
                            'title': '{leftBox|总的运行时长}\n{leftBoxLine2|' + (channelType == 0 ? this.materialDetail.com.runTime : this.materialDetail.mon.runTime) + '小时}'
                        }
                        let seriesName = channelType == 0 ? "COM 通道" : "MON 通道"
                        this.renderChart(data[0], 'flightPanel', "小时", true, this.barChartCache.title, seriesName, [])
                    }
                }).catch((e) => {
                    this.loading = false
                })
            },
            queryStatisticsFlightTimeCount() {
                this.dialogFormVisible = false
                this.$store.dispatch('devicedata/querydevicestatisticstimecount', {
                    companyId: null,
                    timeBy: null,
                    timeNo: null,
                    sn: this.materialDetail.materialCode
                }).then((data) => {
                    this.dialogFormVisible = false
                    this.loading = false
                    if(data != null && data.timeDatas != null) {
                        for (let inx in data.timeDatas) {
                            data.timeDatas[inx].val = parseFloat((data.timeDatas[inx].val/60/60).toFixed(3)) == 0 ? (data.timeDatas[inx].val/60/60) : (data.timeDatas[inx].val/60/60).toFixed(3)
                        }
                    }
                    this.barChartCache = {
                        'data': data,
                        'div': 'flightPanel',
                        'unit': "小时",
                        'title': "{leftBox|总的飞行时长}\n{leftBoxLine2|"+ (this.materialDetail.extendDetail.totalFlightTime/60/60).toFixed(3) +"小时}"
                    }
                    this.renderChart(data, 'flightPanel', "小时", true, this.barChartCache.title, "飞行时长")
                }).catch((e) => {
                    this.loading = false
                })
            },
            queryStatisticsDataSizeCount() {
                this.dialogFormVisible = false
                this.$store.dispatch('devicedata/querydevicestatisticsdatasizecount', {
                    companyId: null,
                    timeBy: null,
                    timeNo: null,
                    sn: this.materialDetail.materialCode
                }).then((data) => {
                    this.dialogFormVisible = false
                    this.loading = false
                    if(data != null && data.timeDatas != null) {
                        for (let inx in data.timeDatas) {
                            data.timeDatas[inx].val = parseFloat((data.timeDatas[inx].val/1024/1024).toFixed(3)) == 0 ? (data.timeDatas[inx].val/1024/1024) : (data.timeDatas[inx].val/1024/1024).toFixed(3)
                        }
                    }
                    this.barChartCache = {
                        'data': data,
                        'div': 'flightPanel',
                        'unit': "MB",
                        'title': "{leftBox|总的数据量}\n{leftBoxLine2|"+ (this.materialDetail.extendDetail.totalDatasize/1024/1024).toFixed(3) +"MB}"
                    }
                    this.renderChart(data, 'flightPanel', "MB", true, this.barChartCache.title, "数据量")
                }).catch((e) => {
                    this.loading = false
                })
            },
            queryStatisticsTaskCount() {
                this.dialogFormVisible = false
                this.$store.dispatch('devicedata/querydevicestatisticstaskcount', {
                    companyId: null,
                    timeBy: null,
                    timeNo: null,
                    sn: this.materialDetail.materialCode
                }).then((data) => {
                    this.dialogFormVisible = false
                    this.barChartCache = {
                        'data': data,
                        'div': 'flightPanel',
                        'unit': "次",
                        'title': "{leftBox|总的飞行架次}\n{leftBoxLine2|"+ this.materialDetail.extendDetail.totalFlightTask +"次}"
                    }
                    this.renderChart(data, 'flightPanel', "次", true, this.barChartCache.title, "飞行架次")
                }).catch((e) => {
                    this.loading = false
                })
            },
            ceilToDecimal(num) {
                const multiplier = this.getNumberLength(num);
                if (multiplier.integerDigits > 0) {
                    let res = 1
                    for (let i = 0; i < multiplier.integerDigits; i++) {
                        res = res * 10
                    }
                    return res
                } else {
                    let res = 1
                    for (let i = 0; i < multiplier.decimalPlaces; i++) {
                        res = res * 0.1
                    }
                    return res * 10
                }
            },
            roundUpToNearestNumber(n) {
                // 如果小于1并且大于0，按小数点后两位进行规整
                if (n < 1) {
                    const numStr = n.toString();
                    const decimalIndex = numStr.indexOf('.');
                    if (decimalIndex !== -1) {
                    const decimalPart = numStr.slice(decimalIndex + 1); // 获取小数部分
                    if (decimalPart.length > 2) {
                        // 小数部分超过两位，进行规整
                        const firstTwoDigits = parseInt(decimalPart.slice(0, 2), 10);
                        const roundedDecimal = Math.ceil(firstTwoDigits / 10) * 10;  // 向上取整到两位数
                        return parseFloat(numStr.slice(0, decimalIndex + 1) + roundedDecimal.toString().padStart(2, '0'));
                    }
                    }
                } else {
                    // 正常情况下处理大于1的数值
                    const numStr = String(parseInt(n));
                    const length = numStr.length;

                    if (length <= 2) {
                        if(n < 5) {
                            return 5
                        }
                        // 如果是两位数或以下，直接进行规整
                        return Math.ceil(n / 10) * 10;
                    } else {
                        // 获取数值的第二位并进行加1操作
                        const powerOfTen = Math.pow(10, length - 2);
                        let firstTwoDigits = Math.floor(n / powerOfTen);
                        firstTwoDigits += 1;  // 第二位加1
                        return firstTwoDigits * powerOfTen;
                    }
                }
                return n;  // 如果没有触发任何条件，返回原始值
            },
            getNumberLength(number) {
                if (Number.isInteger(number)) {
                    return {
                    integerDigits: number.toString().length,
                    decimalPlaces: 0,
                    };
                } else {
                    const parts = number.toString().split('.');
                    return {
                    integerDigits: parts[0].length,
                    decimalPlaces: parts[1].length,
                    };
                }
            },
            genBarChartLifecyclePhase(extend) {
                if(extend == null || extend.lifecyclePhases == null) {
                    this.materialBarPhases = []
                    return
                }
                
                const startDdates = new Set(); // 用于跟踪已添加的日期，避免重复
                const endDdates = new Set(); // 用于跟踪已添加的日期，避免重复
                const barPhasesData = [];
                this.datepPhaseNameMap = {}
                extend.lifecyclePhases.forEach(phase => {
                    if(phase.phase == 0) {
                        return
                    }

                    const startDate = this.formatDate(phase.startTime);
                    const endDate = phase.endTime == 0 ? this.formatDate(new Date().getTime()) : this.formatDate(phase.endTime);
                    const data = [];
                    // 检查startDate和endDate是否为同一天
                    if (startDate !== endDate) {
                        if (!startDdates.has(startDate)) {
                            data.push({ 
                                    name: this.phaseNameMap[phase.phase], 
                                    xAxis: startDate,
                                    label: {
                                        show: true,
                                        color:  this.phaseTextColorMap[phase.phase],
                                        fontSize: 12,
                                        zIndex: 1000,
                                        fontWeight: 700
                                    },
                                    itemStyle: {
                                        zIndex: 1000,
                                        color:  this.phaseColorMap[phase.phase],
                                        emphasis: {
                                            color: this.phaseTextColorMap[phase.phase]  // 设置悬浮时区域的颜色
                                        }
                                    }
                                });
                            startDdates.add(startDate);

                            this.datepPhaseNameMap["" + startDate] = this.phaseNameMap[phase.phase]
                        }
                        if (!endDdates.has(endDate)) {
                            data.push({ 
                                xAxis: endDate,
                                itemStyle: {
                                    zIndex: 1000,
                                    color:  this.phaseColorMap[phase.phase],
                                    emphasis: {
                                        color: this.phaseTextColorMap[phase.phase]  // 设置悬浮时区域的颜色
                                    }
                                }
                            });
                            endDdates.add(endDate);
                        }
                        barPhasesData.push(data)
                    }
                });
                this.materialBarPhases = barPhasesData
            },
            formatDate(timestamp) {
                const date = new Date(timestamp);
                return `${date.getFullYear()}.${date.getMonth() + 1}.${date.getDate()}日`;
            },
            renderChart(data, chartDiv, unit, isSingle, titleText, seriesName, legend) {
                this.isSingleChart = isSingle
                
                let biggest = 0
                if(isSingle) {
                    const xAxisArray = []
                    const yValueArray = []

                    let timeUnitStr = this.timeUnits[data.timeUnit]
                    data.timeDatas.forEach(function(elem) {
                        const xAxis = elem.date + timeUnitStr
                        xAxisArray.push(xAxis)
                        yValueArray.push(elem.val)
                        if (parseFloat(elem.val) > biggest) {
                            biggest = elem.val
                        }
                    })
                    
                    let maxVal = 5
                    const res = this.roundUpToNearestNumber(biggest)
                    if(biggest > maxVal) {
                        maxVal = res
                    }

                    this.rendeSingleLineChart(xAxisArray, yValueArray, chartDiv, unit, titleText, seriesName, maxVal)
                }
                else {
                    const xAxisArray = []
                    const yValueArray = []
                    const zValueArray = []
                    let timeUnitStr = this.timeUnits[data[0].timeUnit]

                    let biggest = 0
                    data[0].timeDatas.forEach(function(elem) {
                        const xAxis = elem.date + timeUnitStr
                        // xAxisArray.push(xAxis)  只能一次
                        yValueArray.push(elem.val)
                        if (parseFloat(elem.val) > biggest) {
                            biggest = elem.val
                        }
                    })

                    data[1].timeDatas.forEach(function(elem) {
                        const xAxis = elem.date + timeUnitStr
                        xAxisArray.push(xAxis)
                        zValueArray.push(elem.val)
                        if (parseFloat(elem.val) > biggest) {
                            biggest = elem.val
                        }
                    })

                    let maxVal = 5
                    const res = this.roundUpToNearestNumber(biggest)
                    if(biggest > maxVal) {
                        maxVal = res
                    }

                    this.renderBarChart(xAxisArray, yValueArray, zValueArray, chartDiv, unit, titleText, legend, maxVal)
                }
            },
            rendeSingleLineChart(xAxisArray, yValueArray, chartDivId, unitStr, titleText, seriesName, yAxisMax) {
                // 基于准备好的dom，初始化echarts实例
                var chartDom = document.getElementById(chartDivId);
                if (chartDom == null) {
                    return
                }

                 // 获取图表容器
                var myChart = echarts.init(chartDom);
                // 清空图表
                myChart.clear();

                let datePhaseMap = this.datepPhaseNameMap
                // 配置项
                var option = {
                    title: {
                        text: titleText,  // '{leftBox|总的故障数}{rightBox|com通道：10个}\n{leftBoxLine2|160个}{rightBox|mon通道：6个}'
                        textStyle: {
                            rich: {
                                leftBox: {
                                    fontSize: 12,
                                    color: '#ffffff',
                                    backgroundColor: '#ff7319',
                                    padding: [5, 5, 5, 5],
                                    width: 100,
                                    height: 22,
                                    lineHeight: 25,
                                    align: 'center',
                                    zIndex: 999
                                },
                                leftBoxLine2: {
                                    fontSize: 18,
                                    color: '#ffffff',
                                    backgroundColor: '#ff7319',
                                    padding: [5, 5, 5, 5],
                                    width: 100,
                                    height: 20,
                                    lineHeight: 20,
                                    align: 'center'
                                },
                                rightBox: {
                                    fontSize: 12,
                                    color: '#000000',
                                    backgroundColor: '#cFcFc1',
                                    padding: [10, 10],
                                    width: 100,
                                    height: 18,
                                    lineHeight: 18,
                                    align: 'left'
                                }
                            }
                        }
                    },
                    tooltip: {
                        trigger: 'axis'
                    },
                    color:['#3d79ff', '#ff8555'],
                    legend: {
                        data: [], // 更新图例名称  // 'COM 通道'
                        right: 20, // 图例靠右展示，距离右边20px
                        top: '10px',     // 图表上边距，留出空间给标题和图例
                        itemWidth: 10,
                        itemHeight: 10,
                        icon: "rect", 
                        orient: 'vertical',
                    },
                    grid: {
                        left: '60px',   // 图表左边距
                        right: '20px',  // 图表右边距
                        top: '88px',     // 图表上边距，留出空间给标题和图例
                        bottom: '60px'   // 图表下边距
                    },
                    xAxis: {
                        type: 'category',
                        data: xAxisArray,
                        // name: unitStr // 单位字符串
                        // 更改坐标轴颜色
                        axisTick: {
                            alignWithLabel: true,  // 柱状是否显示在刻度上
                            show: false,
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                width:1,
                                color: '#E4E4E4'
                            },
                            onZero: false
                        },
                        // x轴的字体样式
                        axisLabel: {
                            formatter: function (value) {
                                return datePhaseMap[value] != null ? ("" + value + "(" + datePhaseMap[value] + ")") : value; // 将单位添加到每个刻度值后面
                            },
                            textStyle: {
                                color: '#666666', // 更改坐标轴文字颜色
                                fontSize: 9, // 更改坐标轴文字大小
                                fontFamily: 'PingFang SC, PingFang SC-Regular'
                            }
                        }
                    },
                    yAxis: {
                        type: 'value',
                        min: 0,
                        max: yAxisMax,
                        // name: unitStr,
                        axisLabel: {
                            formatter: function (value) {
                                return value + ' ' + unitStr; // 将单位添加到每个刻度值后面
                            },
                            textStyle: {
                                color: '#666666', // 更改坐标轴文字颜色
                                fontSize: 9, // 更改坐标轴文字大小
                                fontFamily: 'PingFang SC, PingFang SC-Regular'
                            }
                        },
                        axisTick: {
                            show: false,
                        },
                        // 更改坐标轴颜色
                        axisLine: {
                            show: false,
                            lineStyle: {
                                color: '#E4E4E4'
                            }
                        },
                        // 网格线
                        splitLine: {
                            // 网格线
                            lineStyle: {
                                type: 'solid',
                                with: 0.5,
                                color:  '#E4E4E4'
                            }
                        }
                    },
                    series: [
                        {
                            name: seriesName, // 更新系列名称
                            type: 'line',
                            data: yValueArray,
                            smooth: true, // 平滑曲线
                            symbol: 'circle', // 折线图点的形状
                            connectNulls: false, // 连接缺失数据点
                            symbolSize: (yValueArray != null && yValueArray.length > 1) ? 1 : 5,    // 折线图点的大小
                            lineStyle: {
                                color: '#3d79ff'
                            },
                            markArea: {
                                silent: true,
                                data: this.materialBarPhases
                            }
                        }
                    ],
                    dataZoom: [{ // 这部分是拖动功能的配置
                        type: 'slider', // 使用滑动条形式的 dataZoom 组件
                        start: 0, // 滑动条的起始位置，表示数据窗口包含数据系列的前 0%
                        end: 100, // 滑动条的结束位置，表示数据窗口包含数据系列的后 100%
                        handleSize: 16,
                        height: 26
                    }]
                };

                // 使用刚指定的配置项和数据显示图表
                myChart.setOption(option);
            },
            renderBarChart(xAxisArray, yValueArray, topValueArray, chartDivId, unitStr, titleText, legend, yAxisMax) {
                // 基于准备好的dom，初始化echarts实例
                var chartDom = document.getElementById(chartDivId);
                if (chartDom == null) {
                    return
                }

                 // 获取图表容器
                var myChart = echarts.init(chartDom);
                // 清空图表
                myChart.clear();

                // 配置项
                var option = {
                    title: {
                        text: titleText,
                        textStyle: {
                            rich: {
                                leftBox: {
                                    fontSize: 12,
                                    color: '#ffffff',
                                    backgroundColor: '#ff7319',
                                    padding: [5, 5, 5, 5],
                                    width: 100,
                                    height: 18,
                                    lineHeight: 18,
                                    align: 'center',
                                    zIndex: 999
                                },
                                leftBoxLine2: {
                                    fontSize: 18,
                                    color: '#ffffff',
                                    backgroundColor: '#ff7319',
                                    padding: [5, 5, 5, 5],
                                    width: 100,
                                    height: 25,
                                    lineHeight: 25,
                                    align: 'center'
                                },
                                rightBox: {
                                    fontSize: 12,
                                    color: '#000000',
                                    backgroundColor: '#cFcFc1',
                                    padding: [10, 10],
                                    width: 100,
                                    height: 18,
                                    lineHeight: 18,
                                    align: 'left'
                                }
                            }
                        }
                    },
                    tooltip: {
                        trigger: 'axis'
                    },
                    color:['#3d79ff', '#ff8555'],
                    legend: {
                        data: ['COM 通道', 'MON 通道'],  // legend,   // ['COM 通道', 'MON 通道'], // 更新图例名称
                        right: 20,      // 图例靠右展示，距离右边20px
                        top: '10px',     // 图表上边距，留出空间给标题和图例
                        itemWidth: 10,
                        itemHeight: 10,
                        icon: "rect", 
                        orient: 'vertical',
                        formatter: function (name) {
                            return legend != null ? legend[name] : "";
                        }
                    },
                    grid: {
                        left: '60px',   // 图表左边距
                        right: '20px',  // 图表右边距
                        top: '88px',     // 图表上边距，留出空间给标题和图例
                        bottom: '60px'   // 图表下边距
                    },
                    xAxis: {
                        type: 'category',
                        data: xAxisArray,
                        // name: unitStr // 单位字符串
                        // 更改坐标轴颜色
                        axisTick: {
                            alignWithLabel: true,  // 柱状是否显示在刻度上
                            show: false,
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                width:1,
                                color: '#E4E4E4'
                            },
                            onZero: false
                        },
                        // x轴的字体样式
                        axisLabel: {
                            textStyle: {
                                color: '#666666', // 更改坐标轴文字颜色
                                fontSize: 9, // 更改坐标轴文字大小
                                fontFamily: 'PingFang SC, PingFang SC-Regular'
                            }
                        }
                    },
                    yAxis: {
                        type: 'value',
                        min: 0,
                        max: yAxisMax,
                        // name: unitStr,
                        axisLabel: {
                            formatter: function (value) {
                                return value + ' ' + unitStr; // 将单位添加到每个刻度值后面
                            },
                            textStyle: {
                                color: '#666666', // 更改坐标轴文字颜色
                                fontSize: 9, // 更改坐标轴文字大小
                                fontFamily: 'PingFang SC, PingFang SC-Regular'
                            }
                        },
                        axisTick: {
                            show: false,
                        },
                        // 更改坐标轴颜色
                        axisLine: {
                            show: false,
                            lineStyle: {
                                color: '#E4E4E4'
                            }
                        },
                        // 网格线
                        splitLine: {
                            // 网格线
                            lineStyle: {
                                type: 'solid',
                                with: 0.5,
                                color:  '#E4E4E4'
                            }
                        }
                    },
                    series: [
                        {
                            name: 'COM 通道', // 更新系列名称
                            type: 'line',
                            data: yValueArray,
                            smooth: true, // 平滑曲线
                            symbol: 'circle', // 折线图点的形状
                            connectNulls: false, // 连接缺失数据点
                            symbolSize: (yValueArray != null && yValueArray.length > 1) ? 1 : 5,    // 折线图点的大小
                            lineStyle: {
                                color: '#3d79ff'
                            },
                            markArea: {
                                silent:true,
                                data:  this.materialBarPhases
                            }
                        },
                        {
                            name: 'MON 通道', // 更新系列名称
                            type: 'line',
                            data: topValueArray,
                            smooth: true, // 平滑曲线
                            symbol: 'circle', // 折线图点的形状
                            connectNulls: false, // 连接缺失数据点
                            symbolSize: (yValueArray != null && yValueArray.length > 1) ? 1 : 5,    // 折线图点的大小
                            lineStyle: {
                                color: '#ff8555'
                            },
                            markArea: {
                                silent:true,
                                data:  this.materialBarPhases
                            }
                        }
                    ],
                    dataZoom: [{ // 这部分是拖动功能的配置
                        type: 'slider', // 使用滑动条形式的 dataZoom 组件
                        start: 0, // 滑动条的起始位置，表示数据窗口包含数据系列的前 0%
                        end: 100, // 滑动条的结束位置，表示数据窗口包含数据系列的后 100%
                        handleSize: 16,
                        height: 26
                    }]
                };

                // 使用刚指定的配置项和数据显示图表
                myChart.setOption(option);
            },
            chartRadioChange(val) {
                this.radioChartType = val
                let channelShow = this.activeChannleTabName == 'allData' ? 2 : (this.activeChannleTabName == 'com'? 0 : 1)
                if(val == "flighttime") {
                    this.queryStatisticsFlightTimeCount()
                }
                
                if(val == "datasize") {
                    this.queryStatisticsDataSizeCount()
                }

                if(val == "flighttimes") {
                    this.queryStatisticsTaskCount()
                }

                if(val == "fault") {
                    this.queryFaultOverviewStatistics(channelShow)
                }

                if(val == "powertimes") {
                    this.queryDeviceOnPowerStatistics(channelShow)
                }

                if(val == "runtime") {
                    this.queryDeviceRuntimeStatistics(channelShow)
                }
            },
            initCompanyOptions() {
                this.dialogFormVisible = false
                this.$store.dispatch('company/querycompanylist', {
                    pageIndex: 1,
                    pageSize: 2000,
                    searchName: ''
                }).then((data) => {
                    let comOptions = []
                    this.companyMap = {}
                    // 换成Map和初始化为options
                    comOptions.push({
                        value: "",  // 
                        label: "全部公司"
                    })
                    for (let key in data.list) {
                        comOptions.push({
                            value: data.list[key].id,  // 
                            label: data.list[key].name
                        })
                        this.companyMap[data.list[key].id] = data.list[key].name
                    }
                    this.companyOptions = comOptions

                    // 再刷新数据
                    this.refreshMaterialInfo(true)

                    // 再刷新表格数据
                    this.initProjectOptions()
                }).catch((e) => {
                    this.loading = false
                })
            },
            initProjectOptions() {
                this.$store.dispatch('project/queryprojects', {
                    pageIndex: 1,
                    pageSize: 2000,
                    searchName: ''
                }).then((data) => {
                    let proOptions = []
                    this.projectMap = {}
                    // 换成Map和初始化为options
                    proOptions.push({
                        value: "",
                        label: "全部项目"
                    })

                    this.companyProjectsMap[''] = [{
                        value: "",
                        label: "全部项目"
                    }]

                    for (let key in data.list) {
                        proOptions.push({
                            value: data.list[key].id,
                            label: data.list[key].name
                        })

                        this.projectMap[data.list[key].id] = data.list[key].name
                        if (this.companyProjectsMap[data.list[key].companyId] == null) {
                            this.companyProjectsMap[data.list[key].companyId] = [{
                                value: "",
                                label: "全部项目"
                            }]
                        }

                        this.companyProjectsMap[data.list[key].companyId].push({
                            value: data.list[key].id,
                            label: data.list[key].name
                        })
                    }
                    this.projectSelectOptions = proOptions
                    this.projectOptions = proOptions
                    this.orgProjectOptions = proOptions
                    // 再刷新表格数据
                }).catch((error) => {
                    this.loading = false
                    console.log(error)
                })
            },
            initUIConfig() {
                this.uiConfig = {
                    isEditBtnShow: storage.getDataCache("userRights").includes("role.gms.air.device.edit")
                }
            },
            initPage() {
                var browerWidth = window.innerWidth; //浏览器可视宽度
                var browerHeight = window.innerHeight; //浏览器可视宽度
                if (browerHeight < 739) {
                    browerHeight = 739
                } 
                this.pageWH.obdtableHeight =  (browerHeight - 739 ) + 195
                this.pageWH.tasktableHeight =  (browerHeight - 739 ) + 243

                this.pageWH.pieHeight =  (browerHeight - 739 ) + 123
                this.pageWH.barHeight =  (browerHeight - 739 ) + 123

                if (this.pageWH.barHeight < 230) {
                    this.pageWH.barHeight =  230 
                    this.pageWH.pieHeight = 230
                }

                if (this.barChartCache != null) {
                    setTimeout(() => {
                        this.renderChart(this.barChartCache.data, this.barChartCache.div, this.barChartCache.unit, this.isSingleChart, this.barChartCache.title, this.barSeriesName)
                    }, 500);
                }
            }
        },
        mounted: function() {
            this.planeForm = {
                id: '',
                name: '',
                description: '',
                rights: []
             }

             this.materialDetail = {
                company: this.$route.query.companyName,
                companeyId: this.$route.query.companyId, 
                // director: '陶渊明',
                typeName: '',
                materialCode: this.$route.query.materialCode,
                stateKey: 'off',
                mon: {
                    'powerTimes': '-',
                    'runTime': '-',
                    'dkm': '-',
                    'vip': '-',
                    'fpga': '-',
                    'firmware': '-',
                    'type': 'MON通道'
                },
                com: {
                    'powerTimes': '-',
                    'runTime': '-',
                    'dkm': '-',
                    'vip': '-',
                    'fpga': '-',
                    'firmware': '-',
                    'type': 'COM通道'
                },
                single: {
                    'powerTimes': '-',
                    'runTime': '-',
                    'dkm': '-',
                    'vip': '-',
                    'fpga': '-',
                    'firmware': '-',
                    'type': 'MON通道'
                },
                channelView: 'single',
                extendDetail: {
                    "totalComFault": 0,
                    "totalFault": 0,
                    "totalMonFault": 0
                }
             }

             this.taskTableCurrentPage = this.$route.query.tasktableIndex ? parseInt(this.$route.query.tasktableIndex) : this.taskTableCurrentPage
             this.taskTablePageSize = this.$route.query.tasktableSize ?  parseInt(this.$route.query.tasktableSize) : this.taskTablePageSize

            this.treeData = this.data
            this.initPage()
            this.initUIConfig()
            
            this.refreshMaintanceUsers()
            this.initCompanyOptions()
            // this.queryFaultOverviewStatistics(2)
            window.addEventListener('resize', this.initPage)

            const searchTaskIcon = document.querySelector('.input-task-search .el-input__icon')
            if (searchTaskIcon) {
                searchTaskIcon.addEventListener('click', this.refreshMaterialTask)
                searchTaskIcon.classList.add('search-icon-hover')
            }

            const searchFaultIcon = document.querySelector('.input-fault-search .el-input__icon')
            if (searchFaultIcon) {
                searchFaultIcon.addEventListener('click', this.refreshMaterialFaults)
                searchFaultIcon.classList.add('search-icon-hover')
            }
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.initPage)
        },
        components: {
            Check,
            CaretBottom,
            EditPen,
            Calendar,
            CloseBold
        }
    }; 
  </script>
  <style  lang="scss">
  /* 手动配置全局样式 */
  .el-row {
    display: flex;     /*  align-items: center; */
    padding: 10px;
    margin: 5px;
    background: white;
  }

  .el-tabs__item {
     font-family: PingFang SC, PingFang SC-Semibold;
     font-weight: 600;
  }

  .el-tabs__item.is-active, .el-tabs__item:hover {
        color: #02c4e7;
  }

  /* 背景颜色 */
  .aside{
    background-color: white;
  }

  .main-content {
    display: flex;
    align-items: flex-start;
  }
  
  .left-top {
    background: white;
    height: 220px;
  }

  .left-bottom {
    background: white;
    height: 495px;
    overflow: auto;
    padding: 15px;
  }

  .right-top {
    height: 180px;
    background: white;
  }

  .right-bottom  {
    height: 377px;  /* 影响权限表格的高度和  */
    background: white;
    margin-left: 15px;
    margin-right: 15px;
  }
 
  .plane-form-label {
    justify-content: center !important;
  }

  .plane-form {
    margin-top: 20px !important;
    margin-right: 20px !important;
  }

  .el-tree {
    width: 100%;
    font-weight: 500;
    font-size: 16px;
    padding: 10px;
    color: #000;
    --el-tree-node-hover-bg-color: #00ACCD;
  }

  .customWidth {
    width: 450px !important;
  }

  .float_right {
    float: right;
  }


// 树控件-节点之间
.el-tree-node {
    margin: 8px;
}

// 树控件-原有的箭头 去掉
.el-tree .el-icon svg{   
  display: none !important;
  height: 0;
  width: 0;
}

// 树控件-引入的图标（图片）size大小 => 树节点前的预留空间大小
.el-tree-node__expand-icon{ 
  font-size:16px;
}
 
// 树控件-图标是否旋转，如果是箭头类型的，可以设置旋转90度。如果是两张图片，则设为0
.el-tree .el-tree-node__expand-icon.expanded  {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

// 树控件-叶子节点（不显示图标）
.el-tree .is-leaf.el-tree-node__expand-icon::before { 
  display: block;
  background: none !important;
  content: '';
  width: 18px;
  height: 18px;
}

.el-button.is-round {
    padding: 8px 15px;
    border-radius: 4px;
    background: #00accd;
}

.statistic {
    text-align: center;
    margin-left: 10px;
    margin-right: 10px;
}

.option {
    margin-left: 10px;
    margin-top: -5px !important;
  }

  .datepicker {
    margin-right: 10px !important;
  }

  .btn {
    margin-top: -5px !important;
    margin-left: 20px;
    height: 25px;
  }

  .el-table .el-table__header th {
    background-color: #F4F7FA !important;
    border-right: 0 none；
  }

  .el-date-editor{
    width: -webkit-fill-available;
  }

  .el-dropdown-link:focus {
    outline: none;   /* 去除，悬浮在el-dropdown时，黑色边框, */
  }

  .el-descriptions__body .el-descriptions__table:not(.is-bordered) .el-descriptions__cell {
    padding-bottom: 3px !important;
    line-height: 16px !important;
  }

  .el-descriptions__body {
    background: transparent !important;
  }

  .el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active {
    color: #fff !important;
    background-color: #00accd !important;
    border-radius: 5px 5px 0 0;
  }

  .el-tabs--card>.el-tabs__header .el-tabs__item.is-active {
    background: #00accd;
    color: white;
  }

  .el-tabs--card>.el-tabs__header .el-tabs__item {
    background: white;
    border-bottom: 1px solid #f1f1f1;
  }

  
  .el-tabs--border-card>.el-tabs__header .el-tabs__item {
        font-size: 12px;
        color: #333333;
  }

  /* 放到scope无法生效 */
  .el-tabs__header {
    padding: 0;
    position: relative;
    margin: 0 0 1px;   /* 去掉间隙 */
  }

  .input-with-seach {
    width: 200px;
  }

  .el-form-item {
    .el-input {
      width: 320px;
    }
    .el-select{
      width: 320px;
    }
  }

.form-footer {
    float: right;
    justify-items: end;
    margin-top: 20px;
  }

.el-descriptions__label:not(.is-bordered-label) {
    color: #7e8a95;
    font-size: 12px;
}

.el-picker-panel__footer {
    .el-button:focus, .el-button:hover {
        background-color: #02c4e7 !important;
    }
    .el-button.is-disabled, .el-button.is-disabled:focus, .el-button.is-disabled:hover {
        color: #f4f4f5; 
    }
}

.record-content {
  .descItem-material {
    font-weight: 500 !important;
    font-family: PingFang SC, PingFang SC-Medium;
    text-align: left;
    font-size: 14px !important;
    font-weight: 600 !important;
    white-space: nowrap;        /* 防止文本换行 */
    overflow: hidden;           /* 隐藏超出容器的文本 */
    text-overflow: ellipsis;    /* 超出部分显示省略号 */
  }

  .descItem-channel {
    font-weight: 500 !important;
    font-family: PingFang SC, PingFang SC-Medium;
    text-align: left;
    font-size: 12px !important;
  }

  .detail-label {
    width: 100px;
    color: #7E8A95 !important;
    font-size: 12px !important;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: left;
  }

  .el-table__expanded-cell {
    padding: 0px !important;
  }

  .maintanceDetailExpandRow {
    .el-table__body, .el-table__footer, .el-table__header {
        border-bottom: 1px solid #f1f3f4;
    }

    .el-table .el-table__header th {
        background-color: #f1f3f4 !important;
    }

    .el-table__body-wrapper {
        background-color: #f1f3f4 !important;
    }
  }

  .el-table__inner-wrapper .el-table .el-table__cell {
    background: #f1f3f4;
  }
}

.search-icon-hover {
    cursor: pointer;
}
</style>

<style scoped>
  .el-descriptions-item {
    display: block;
  }
</style>