import request from '@/utils/request'
 
export function addAccount(data) {
  return request({
    url: '/bd-gms/customer/user',
    method: 'post',
    data
  })
}

export function getAccountInfo(data) {
  return request({
    url: '/bd-gms/customer/user/info',
    method: 'post',
    data
  })
}

export function getAccount(data) {
  return request({
    url: '/bd-gms/customer/user/info?id=' + data.userId,
    method: 'get',
    data
  })
}

export function getAccountList(data) {
  return request({
    url: '/bd-gms/customer/user/list',
    method: 'post',
    data
  })
}

export function deleteAccount(data) {
    return request({
      url: '/bd-gms/customer/user' + "?userAccountId=" + data.accountId,
      method: 'delete'
    })
  }

  export function resetPassword(data) {
    return request({
      url: '/bd-gms/customer/user/info/password' + "?userAccountId=" + data.accountId,
      method: 'put'
    })
  }


  export function updateAccount(data) {
    return request({
      url: '/bd-gms/customer/user/info',
      method: 'post',
      data
    })
  }

  export function updateUserName(data) {
    return request({
      url: '/bd-gms/customer/user/info/name',
      method: 'post',
      data
    })
  }

  export function updateUserPassword(data) {
    return request({
      url: '/bd-gms/customer/user/info/password',
      method: 'post',
      data
    })
  }