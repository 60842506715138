import request from '@/utils/request' 


export function addRole(data) {
    return request({
    url: '/bd-gms/customer/role',
    method: 'post',
    data
    })
}

export function getRoleInfo(data) {
    return request({
    url: '/bd-gms/customer/role/info',
    method: 'post',
    data
    })
}

export function getRoles(data) {
    return request({
    url: '/bd-gms/customer/role/list',
    method: 'post',
    data
    })
}

export function deleteRole(data) {
    return request({
        url: '/bd-gms/customer/role' + "?roleId=" + data.roleId,
        method: 'delete'
    })
}

export function updateRole(data) {
    return request({
        url: '/bd-gms/customer/role/info',
        method: 'post',
        data
    })
} 
  