import { getFaultstatisticsOverview, getFaultstatisticsOverviewTable, getFaultstatisticsFirmwareversionTopn, getFaultStatisticsFaultmoduleTopn, getFaultStatisticsFaultcodeTopn, getFaultStatisticsCount, getDeviceFaultOverviewStatistics } from '@/api/faultdata'
import { getToken, getUserInfo } from '@/utils/auth'
import { resetRouter } from '@/router'

const getDefaultState = () => {
  return {
    token: getToken(),
    userInfo: getUserInfo(),
    name: '',
    avatar: ''
  }
}

const state = getDefaultState()

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState())
  }
}

const actions = {
  // user login
  queryfaultoverview({ commit }, pageParm) {
    const { lifecyclePhase, projectId, companyId, timeBy, timeNo  } = pageParm
    return new Promise((resolve, reject) => {
        getFaultstatisticsOverview({ lifecyclePhase: lifecyclePhase, projectId: projectId, companyId: companyId, timeBy: timeBy, timeNo: timeNo }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  queryfaultoverviewtable({ commit }, pageParm) {
    const { lifecyclePhase, projectId, companyId, timeBy, timeNo, searchValue,pageIndex, pageSize, status  } = pageParm
    return new Promise((resolve, reject) => {
        getFaultstatisticsOverviewTable({ lifecyclePhase: lifecyclePhase, projectId: projectId, companyId: companyId, timeBy: timeBy, 
          timeNo: timeNo, searchValue: searchValue, status: status }, {pageSize: pageSize, pageIndex: pageIndex}).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  queryfaulttopnfirmwareversion({ commit }, pageParm) {
    const { lifecyclePhase, projectId, companyId, timeBy, timeNo  } = pageParm
    return new Promise((resolve, reject) => {
        getFaultstatisticsFirmwareversionTopn({ lifecyclePhase: lifecyclePhase, projectId: projectId, companyId: companyId, timeBy: timeBy, timeNo: timeNo }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  queryfaulttopnfaultmodule({ commit }, pageParm) {
    const { lifecyclePhase, projectId, companyId, timeBy, timeNo  } = pageParm
    return new Promise((resolve, reject) => {
        getFaultStatisticsFaultmoduleTopn({ lifecyclePhase: lifecyclePhase, projectId: projectId, companyId: companyId, timeBy: timeBy, timeNo: timeNo }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  queryfaulttopnfaultcode({ commit }, pageParam) {
    const { lifecyclePhase, projectId, companyId, timeBy, timeNo  } = pageParam
    return new Promise((resolve, reject) => {
        getFaultStatisticsFaultcodeTopn({ lifecyclePhase: lifecyclePhase, projectId: projectId, companyId: companyId, timeBy: timeBy, timeNo: timeNo }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  queryfaultstatisticscount({ commit }, pageParm) {
    const { lifecyclePhase, projectId, companyId, timeBy, timeNo, metricName  } = pageParm
    return new Promise((resolve, reject) => {
        getFaultStatisticsCount({ lifecyclePhase: lifecyclePhase, projectId: projectId, companyId: companyId, timeBy: timeBy, timeNo: timeNo, metricName: metricName }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  queryfaultoverviewstatistics({ commit }, pageParm) {
    const { projectId, companyId, timeBy, timeNo, channelType, lifecyclePhase: lifecyclePhase, sn  } = pageParm
    return new Promise((resolve, reject) => {
      getDeviceFaultOverviewStatistics({ projectId: projectId, companyId: companyId, timeBy: timeBy, timeNo: timeNo, sn: sn, channelType: channelType }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}