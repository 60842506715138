import { getToken , getUserInfo } from '@/utils/auth'
import { addAuthLicense, getAuthLicenseList, deleteAuthLicense, updateAuthLicense, updateAuthLicenseStstus } from '@/api/authLicense'

const getDefaultState = () => {
  return {
    token: getToken(),
    userInfo: getUserInfo(),
    name: '',
    avatar: ''
  }
}

const state = getDefaultState()

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState())
  }
}
 
const actions = {
    createAuthLicense({ commit }, authLicenseInfo) {
    const { name, company, num, startTime, endTime, desc } = authLicenseInfo
    return new Promise((resolve, reject) => {
        addAuthLicense({ appName: name, companyId: company, authNum: num, startDate: startTime, endDate: endTime, description: desc }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  queryAuthLicenselist({ commit }, pageParam) {
    const { pageSize, pageIndex, searchName, company, status } = pageParam
    return new Promise((resolve, reject) => {
        getAuthLicenseList({ pageSize: pageSize, pageIndex: pageIndex, searchName: searchName, companyId: company, status: status }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  
  deleteAuthLicensebyId({ commit }, authLicenseInfo) {
    const { authLicenseId } = authLicenseInfo
    return new Promise((resolve, reject) => {
        deleteAuthLicense({ id: authLicenseId }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  updateAuthLicense({ commit }, authLicenseInfo) {
    const { authLicenseId, name, company, num, startTime, endTime, desc } = authLicenseInfo
    return new Promise((resolve, reject) => {
        updateAuthLicense({ id: authLicenseId, appName: name, companyId: company, authNum: num, startDate: startTime, endDate: endTime, description: desc}).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  updateAuthLicenseStstus({ commit }, authLicenseInfo) {
    const { authLicenseId, status } = authLicenseInfo
    return new Promise((resolve, reject) => {
        updateAuthLicenseStstus({ id: authLicenseId, status: status }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

