import request from '@/utils/request'
import axios from 'axios'

export function addFirmware(data) {
  return request({
    url: '/bd-gms/firmware/info',
    method: 'post',
    data
  })
}
 
export function getFirmwareList(data) {
  return request({
    url: '/bd-gms/firmware/list',
    method: 'post',
    data
  })
}

export function downloadFirmware(data, onDownloadProgress) {
    return request({
        url: '/bd-gms/firmware/file?id=' + data.id,
        method: 'get',
        responseType: 'blob',
        data,
        onDownloadProgress: onDownloadProgress
    })
}

export function downloadFirmwares(data, onDownloadProgress) {
    return request({
        url: '/bd-gms/firmware/files',
        method: 'post',
        responseType: 'blob',
        data,
        onDownloadProgress: onDownloadProgress
    })
}

export function deleteFirmware(data) {
    return request({
        url: '/bd-gms/firmware/info' + "?id=" + data.id,
        method: 'delete'
    })
}

export function deleteFirmwares(data) {
    return request({
        url: '/bd-gms/firmware/list',
        method: 'delete',
        data
    })
}

export function updateFirmware(data) {
    return request({
        url: '/bd-gms/firmware/info',
        method: 'post',
        data
    })
}

export function addFirmwareType(data) {
    return request({
      url: '/bd-gms/firmware/type',
      method: 'post',
      data
    })
  }
   
  export function deleteFirmwareType(data) {
    return request({
        url: '/bd-gms/firmware/type' + "?id=" + data.id,
        method: 'delete'
    })
}

export function getFirmwareTypeList(data) {
    return request({
        url: '/bd-gms/firmware/types',
        method: 'post',
        data
    })
}