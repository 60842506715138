import request from '@/utils/request' 


export function addProject(data) {
    return request({
    url: '/bd-gms/customer/project',
    method: 'post',
    data
    })
}

export function getProjectInfo(data) {
    return request({
    url: '/bd-gms/customer/project/info',
    method: 'post',
    data
    })
}

export function getProjects(data) {
    return request({
    url: '/bd-gms/customer/project/list',
    method: 'post',
    data
    })
}

export function deleteProject(data) {
    return request({
        url: '/bd-gms/customer/project' + "?projectId=" + data.projectId,
        method: 'delete'
    })
}

export function updateProject(data) {
    return request({
        url: '/bd-gms/customer/project/info',
        method: 'post',
        data
    })
}