import { getOnlineMaterials, getOnlineMaterialTasks, getMaterialRealTimeInfo, getMaterialRealTimeTask } from '@/api/flightMonitor'
import { getToken, getUserInfo } from '@/utils/auth'
import { resetRouter } from '@/router'

const getDefaultState = () => {
  return {
    token: getToken(),
    userInfo: getUserInfo(),
    name: '',
    avatar: ''
  }
}

const state = getDefaultState()

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState())
  }
}

const actions = {
  // user login
  queryOnlineMaterials({ commit }, pageParam) {
    const { materialCode } = pageParam
    return new Promise((resolve, reject) => {
        getOnlineMaterials({ code: materialCode }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  queryOnlineMaterialTasks({ commit }, pageParam) {
    const { materialCode } = pageParam
    return new Promise((resolve, reject) => {
        getOnlineMaterialTasks({ code: materialCode }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  queryMaterialRealTimeInfo({ commit }, pageParam) {
    const { materialId  } = pageParam
    return new Promise((resolve, reject) => {
        getMaterialRealTimeInfo({ materialId: materialId }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  queryMaterialRealTimeTask({ commit }, pageParam) {
    const { taskId, materialId   } = pageParam
    return new Promise((resolve, reject) => {
        getMaterialRealTimeTask({ taskId: taskId, materialId: materialId }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

