import CryptoJS from 'crypto-js';
const key = CryptoJS.enc.Utf8.parse('BoundaryAI666666');
const iv = CryptoJS.enc.Utf8.parse('c558Gq0YQK2QUlMc');

export function encrypt(word) {
  let srcs = CryptoJS.enc.Utf8.parse(word);
  let encrypted = CryptoJS.AES.encrypt(srcs, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
  let encryptedHex = encrypted.ciphertext.toString().toUpperCase();
  let encryptedHexArray = CryptoJS.enc.Hex.parse(encryptedHex);
  let encryptedBase64 = CryptoJS.enc.Base64.stringify(encryptedHexArray);
  return encryptedBase64;
}

export function decrypt(word) {        
  // let encryptedHexStr = CryptoJS.enc.Hex.parse(word);        
  // let srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr);
  let decrypt = CryptoJS.AES.decrypt(word, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
  console.log(CryptoJS.pad.Pkcs7)
  let decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
  return decryptedStr.toString();
}

export function enMD5(word) {    
  // 改为配置项，从后台获取    
  const saltedPassword = word + 'BoundaryAI666...';
  const encryptedPassword = CryptoJS.MD5(saltedPassword).toString();
  return encryptedPassword;
}
