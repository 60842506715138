import { auth, config } from '@/api/dingding'
import { getToken, setToken, removeToken, getUserInfo, removetUserInfo, setUserInfo, setRefreshToken, setTokenExpiration } from '@/utils/auth'
import { resetRouter } from '@/router'

const getDefaultState = () => {
  return {
    token: getToken(),
    userInfo: getUserInfo(),
    name: '',
    avatar: ''
  }
}

const state = getDefaultState()

// 跟发送请求 401 有关
const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState())
  },
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_USER_INFO: (state, userInfo) => {
    state.userInfo = userInfo
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  }
}

const actions = {
  // user login
  auth({ commit }, userInfo) {
    const { authCode } = userInfo
    return new Promise((resolve, reject) => {
        auth({ authCode: authCode }).then(response => {
        const data = response
        data['userCode'] = data.userCode
        commit('SET_TOKEN', data.userCode)
        commit('SET_USER_INFO', data)
        setToken(data.token)
        setRefreshToken(data.refresh_token)
        setUserInfo(data)
        setTokenExpiration(data.expiration_date)
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  config({ commit }, userInfo) {
    const { } = userInfo
    return new Promise((resolve, reject) => {
      config({ }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

