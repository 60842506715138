import { getToken , getUserInfo } from '@/utils/auth'
import { addRole, getRoles, deleteRole, updateRole } from '@/api/role'

const getDefaultState = () => {
  return {
    token: getToken(),
    userInfo: getUserInfo(),
    name: '',
    avatar: ''
  }
}

const state = getDefaultState()

const mutations = {
   
}
 
const actions = {
  createrole({ commit }, roleInfo) {
    const { name, description, rights } = roleInfo
    return new Promise((resolve, reject) => {
        addRole({ name: name, description: description, rights: rights }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  queryroles({ commit }, param) {
    const { searchName } = param
    return new Promise((resolve, reject) => {
        getRoles({ searchName }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  
  deleterole({ commit }, roleInfo) {
    const { roleId } = roleInfo
    return new Promise((resolve, reject) => {
        deleteRole({ roleId: roleId }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  updaterole({ commit }, roleInfo) {
    const { roleId, name, description, rights } = roleInfo
    return new Promise((resolve, reject) => {
        updateRole({ id: roleId, name: name, description: description, rights: rights }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

