import { getPlaneList, addPlane, deletePlane, updatePlane } from '@/api/plane'
import { getToken, getUserInfo } from '@/utils/auth'
import { resetRouter } from '@/router'

const getDefaultState = () => {
  return {
    token: getToken(),
    userInfo: getUserInfo(),
    name: '',
    avatar: ''
  }
}

const state = getDefaultState()

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState())
  }
}

const actions = {
  // user login
  createplane({ commit }, userInfo) {
    const { planeCode, companyId, type, materials } = userInfo
    return new Promise((resolve, reject) => {
        addPlane({ planeCode: planeCode, companyId: companyId, type: type, materialIds: materials }).then(response => {
        const data = response
         
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  queryplanelist({ commit }, pageParm) {
    const { pageSize, pageIndex, searchName, companyId, type, status  } = pageParm
    return new Promise((resolve, reject) => {
        getPlaneList({ pageSize: pageSize, pageIndex: pageIndex, searchName: searchName, companyId: companyId, 
          type: type, status: status  }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  deleteplanebyid({ commit }, planeInfo) {
    const { planeId } = planeInfo
    return new Promise((resolve, reject) => {
        deletePlane({ planeId: planeId }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  updateplane({ commit }, planeInfo) {
    const { planeId, planeCode, companyId, type, materials } = planeInfo
    return new Promise((resolve, reject) => {
        updatePlane({ id: planeId, planeCode: planeCode, companyId: companyId, type: type, materialIds: materials }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}