import request from '@/utils/request'
 
export function addUser(data) {
  return request({
    url: '/bd-gms/maintance/user',
    method: 'post',
    data
  })
}
 

export function getUser(data) {
  return request({
    url: '/bd-gms/maintance/user?userId=' + data.userId,
    method: 'get',
    data
  })
}

export function getUserList(data) {
  return request({
    url: '/bd-gms/maintance/users',
    method: 'post',
    data
  })
}

export function deleteUser(data) {
    return request({
      url: '/bd-gms/maintance/user' + "?userId=" + data.accountId,
      method: 'delete'
    })
  }

  export function resetPassword(data) {
    return request({
      url: '/bd-gms/maintance/user/info/password' + "?userId=" + data.accountId,
      method: 'put'
    })
  }


  export function updateUser(data) {
    return request({
      url: '/bd-gms/maintance/user/info',
      method: 'post',
      data
    })
  }

  export function updateUserName(data) {
    return request({
      url: '/bd-gms/maintance/user/info/name',
      method: 'post',
      data
    })
  }

  export function updateUserPassword(data) {
    return request({
      url: '/bd-gms/maintance/user/info/password',
      method: 'post',
      data
    })
  }


  export function getRoleList(data) {
    return request({
      url: '/bd-gms/maintance/roles',
      method: 'post',
      data
    })
  }