import { getUserList, addUser, deleteUser, resetPassword, updateUser,getUser, updateUserName, updateUserPassword, getRoleList } from '@/api/maintUser'
import { getToken, getUserInfo } from '@/utils/auth'
import { resetRouter } from '@/router'

const getDefaultState = () => {
  return {
    token: getToken(),
    userInfo: getUserInfo(),
    name: '',
    avatar: ''
  }
}

const state = getDefaultState()

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState())
  }
}

const actions = {
  // user login
  createuser({ commit }, userInfo) {
    const { name, companyId, role, endDate, startDate } = userInfo
    return new Promise((resolve, reject) => {
        addUser({ name: name, companyId: companyId, role: role, startDate: startDate, endDate: endDate  }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  getuser({ commit }, userInfo) {
    const { id } = userInfo
    return new Promise((resolve, reject) => {
        getUser({  userId: id  }).then(response => {
        const data = response
         
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  queryuserlist({ commit }, pageParm) {
    const { pageSize, pageIndex, searchName, companyId, roleId  } = pageParm
    return new Promise((resolve, reject) => {
        getUserList({ pageSize: pageSize, pageIndex: pageIndex, searchName: searchName, companyId: companyId, roleId: roleId  }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  deleteuserbyid({ commit }, useraccountInfo) {
    const { useraccountId } = useraccountInfo
    return new Promise((resolve, reject) => {
        deleteUser({ accountId: useraccountId }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  resetpasswordbyid({ commit }, useraccountInfo) {
    const { useraccountId } = useraccountInfo
    return new Promise((resolve, reject) => {
        resetPassword({ accountId: useraccountId }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  updateuser({ commit }, useraccountInfo) {
    const { useraccountId, company, email, role, userCode, name, endDate, startDate} = useraccountInfo
    return new Promise((resolve, reject) => {
        updateUser({ id: useraccountId, name: name, companyId: company, email: email, role: role, userCode: userCode, startDate: startDate, endDate: endDate }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  updateusername({ commit }, useraccountInfo) {
    const { useraccountId, name} = useraccountInfo
    return new Promise((resolve, reject) => {
        updateUserName({ id: useraccountId, name: name }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  updateuserpassword({ commit }, useraccountInfo) {
    const { useraccountId, password, newPassword, confirmPassword} = useraccountInfo
    return new Promise((resolve, reject) => {
      updateUserPassword({ id: useraccountId, password: password, newPassword: newPassword, confirmPassword: confirmPassword }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  
queryrolelist({ commit }, pageParm) {
    const { pageSize, pageIndex, searchName, companyId } = pageParm
    return new Promise((resolve, reject) => {
        getRoleList({ pageSize: pageSize, pageIndex: pageIndex, searchName: searchName }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

