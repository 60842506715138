<template>
    <el-row style="box-shadow: 0px 2px 6px 2px rgba(138,152,180,0.14); ">
        <div class="title-flag" style="color: #333333; width: 100%; font-weight: 700; margin: 10px; padding-bottom: 5px; border-bottom: #c4f0f8 1px solid;">
            基本信息
        </div>
        <el-col>
            <el-form   ref="materialform" :model="materialform" label-position="left">
                <el-row>
                    <el-col :span="14">
                        <el-form-item label="编号" :label-width="formLabelWidth">
                            <el-input v-model="materialform.materialCode" autocomplete="off" placeholder="输入设备编号"  disabled="true" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="2">
                        <!--el-form-item label="所属飞机：" :label-width="formLabelWidth">
                            <el-select v-model="materialform.plane" placeholder="请选择飞机">
                                <el-option
                                    v-for="item in planeOptions"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                />
                            </el-select>
                        </el-form-item-->
                    </el-col>
                    <el-col :span="8"></el-col>
                </el-row>
                
                <el-row>
                    <el-col :span="14" class="editdatepicker">
                        <el-form-item label="出厂日期" :label-width="formLabelWidth">
                            <el-date-picker
                                v-model="materialform.createdTime"
                                type="date"
                                placeholder="选择出厂日期"
                                disabled="true"
                                :prefix-icon="customPrefix"
                                style="width: -webkit-fill-available !important; width:320px"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="2">
                        <!--el-form-item label="绑定日期：" :label-width="formLabelWidth">
                            <el-date-picker
                                v-model="materialform.bindTime"
                                type="datetime"
                                placeholder="选择出厂日期"
                                disabled="true"
                                style="width: -webkit-fill-available !important;"
                            />
                        </el-form-item-->
                    </el-col>
                    <el-col :span="8"></el-col>
                </el-row>

                
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="所属型号" :label-width="formLabelWidth">
                            <el-select v-model="materialform.type" placeholder="请选择类型" clearable :suffix-icon="CaretBottom">
                                <el-option
                                    v-for="item in typeOptions"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8"></el-col>
                    <el-col :span="8"></el-col>
                </el-row>   
                
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="所属公司" :label-width="formLabelWidth">
                            <el-select v-model="materialform.company" filterable placeholder="请选择所属公司" @change="handleCompanySelectChange" :suffix-icon="CaretBottom">
                                <el-option
                                    v-for="item in companyOptions"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8"></el-col>
                    <el-col :span="8"></el-col>
                </el-row>
                
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="所属项目" :label-width="formLabelWidth">
                            <el-select v-model="materialform.projectId" placeholder="请选择项目" :suffix-icon="CaretBottom">
                                <el-option
                                    v-for="item in projectSelectOptions"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8"></el-col>
                    <el-col :span="8"></el-col>
                </el-row> 

                <el-row>
                    <el-col :span="8">
                        <el-form-item label="生命周期" :label-width="formLabelWidth">
                            <el-select v-model="materialform.lifecyclePhase" placeholder="请选择周期" :suffix-icon="CaretBottom">
                                <el-option
                                    v-for="item in lifecyclePhaseOptions"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8"></el-col>
                    <el-col :span="8"></el-col>
                </el-row>
                
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="备注：" :label-width="formLabelWidth" prop="description">
                            <el-input v-model="materialform.description" autocomplete="off" placeholder="请输入备注" :rows="6" type="textarea"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8"></el-col>
                    <el-col :span="8"></el-col>
                </el-row>
            </el-form>
        </el-col>
    </el-row>

    <span  class="form-footer" :style="{ 'margin-bottom': pageWH.editMarginHeight + 'px' }">
        <el-button type="primary" @click="addMaterial" color="#00ACCD" style="padding-left:40px;padding-right:40px;">保存</el-button>
        <el-button @click="linkbacktopage" style="padding-left:40px;padding-right:40px; padding-top: 10px; padding-bottom: 10px;border: #cccccca1 1px solid !important; ">取消</el-button>
    </span>
</template>


<script>
import { reactive } from 'vue'
import { Search, Plus, CaretBottom } from '@element-plus/icons-vue'
import storage from '@/utils/storage'
import { ElMessage } from 'element-plus';

// 如果 script用了setup, 则不能用export，可对比之前的文件; 相关this和v-model引用的变量一定要在const和return定义
export default {
name: 'CompanyManager',
data() {
        const currPlaneId = ''
        const hideEdit = true
        const showEdit = false
        const dialogFormVisible = false
        const formLabelWidth = '100px'
        const searchName = ''
        const totalNum = 0
        const currentPage = 0
        const pageSize = 0
        const defaultTime = new Date(2000, 1, 1, 12, 0, 0) // '12:00:00'
        const materialform = reactive({
            name: '',
            email: '',
            company: '',
            type: '',
            materialCode: ''
        })
        const uiConfig = {}
        const companyColumnVisible = false        
        const selectedType = ''
        const selectedStatus = ''
        const selectedCompany = ''
        const selectedCompanyName = '全部公司'
        const selectedStatusName = '全部状态'
        const selectedTypeName = '全部型号'

        const tableData = []
 
        const companyOptions = []

        const planeOptions  = []

        const companyMap = {}
        const typeMap = {
            '': '-',
            // '01': '单通道计算机',
            // '02': '双通道计算机',
            '01': 'R3',
            '02': 'REG300'
        }
        const statusMap  = {
            on: '在线',
            off: '离线'
        }
        const sourceFromMap  = {
            2: '全部来源',
            0: '注册',
            1: '系统'
        }
        const statusOptions = [
            {
                value: '',
                label: '全部状态',
            },
            {
                value: 'on',
                label: '在线',
            },
            {
                value: 'off',
                label: '离线',
            }
        ]
        const typeOptions = [
            {
                value: '',
                label: '全部类型',
            },
            {
                value: '01',
                label: 'R3',
            },
            {
                value: '02',
                label: 'REG300',
            }
        ]

 
         
        const handleCompanySelectChange = (selectVal) => {
            this.materialform.projectId = ""
            this.projectSelectOptions =  selectVal == "" ? this.orgProjectOptions : (this.companyProjectsMap[selectVal] != null ? this.companyProjectsMap[selectVal] : [{  value: "",  label: "全部项目" }])
        }

         
        return {
            uiConfig,
            hideEdit,
            showEdit,
            Plus,
            Search,
            CaretBottom,
            customPrefix: '',
            companyColumnVisible,
            loading: false,
            currentPage,
            pageSize,
            searchName,
            totalNum,
            companyOptions,
            planeOptions,
            companyMap,
            projectMap: {},
            currPlaneId,
            typeMap,
            statusMap,
            sourceFromMap,
            typeOptions,
            projectSelectOptions: [],
            lifecyclePhaseOptions: [{
                value: 0,
                label: "全部阶段",
            },{
                value: 1,
                label: "研发",
            },{
                value: 2,
                label: "服役",
            },{
                value: 3,
                label: "维修",
            },{
                value: 4,
                label: "报废",
            }],
            lifecyclePhaseMap: {
                0: "全部阶段",
                1: "研发",
                2: "服役",
                3: "维修",
                4: "报废"
            },
            sourceFromOptions: [{
                value: 2,
                label: "全部来源",
            },{
                value: 0,
                label: "注册",
            },{
                value: 1,
                label: "系统",
            }],
            selectedSourceFromName: "全部来源",
            selectedSourceFrom: 2,
            selectedLifecyclePhaseName: "全部阶段",
            selectedLifecyclePhase: 0,
            projectOptions: [],
            orgProjectOptions: [],
            companyProjectsMap: {},
            statusOptions,
            materialform,
            selectedType,
            selectedStatus,
            selectedCompany,
            selectedCompanyName,
            selectedStatusName,
            selectedTypeName,
            selectedProjectName: '全部项目',
            tableData,
            dialogFormVisible,
            formLabelWidth,
            defaultTime,
            pageWH: {},
            currMaterialCode: "",
            handleCompanySelectChange,
        };
    },
    methods: {
        clearVlid() {
            if (this.$refs.materialform) {
                this.$refs.materialform.clearValidate();  /* 清理校验 */
            } else {
                setTimeout(() => {
                    this.$refs.materialform.clearValidate();  /* 清理校验 */ 
                }, 200);
            }
        },
        addMaterial() {
            if(this.editingMaterialId != null && this.editingMaterialId != '') {
                this.updateMaterialById()
            } else {
                console.log('add company')
                this.$store.dispatch('material/creatematerial', {
                    type: '1',
                    materialCode: 'RWB23949'
                }).then(() => {
                    this.showEdit = false
                    this.hideEdit = true
                    this.dialogFormVisible = false
                    this.loading = false
                    this.refreshMaterialTable()
                }).catch((e) => {
                    this.loading = false
                })
            }
        },
        initCompanyOptions() {
            this.$store.dispatch('company/querycompanylist', {
                pageIndex: 1,
                pageSize: 2000,
                searchName: ''
            }).then((data) => {
                let comOptions = []
                this.companyMap = {}
                // 换成Map和初始化为options
                comOptions.push({
                    value: "",  // 
                    label: "全部公司"
                })
                for (let key in data.list) {
                    comOptions.push({
                        value: data.list[key].id,  // 
                        label: data.list[key].name
                    })
                    this.companyMap[data.list[key].id] = data.list[key].name
                }
                this.companyOptions = comOptions
                // 再刷新表格数据
                this.initProjectOptions()
            }).catch((error) => {
                this.loading = false
                console.log(error)
            })
        },
        initProjectOptions() {
            this.$store.dispatch('project/queryprojects', {
                pageIndex: 1,
                pageSize: 2000,
                searchName: ''
            }).then((data) => {
                let proOptions = []
                this.projectMap = {}
                // 换成Map和初始化为options
                proOptions.push({
                    value: "",
                    label: "全部项目"
                })

                this.companyProjectsMap[''] = [{
                    value: "",
                    label: "全部项目"
                }]

                for (let key in data.list) {
                    proOptions.push({
                        value: data.list[key].id,
                        label: data.list[key].name
                    })

                    this.projectMap[data.list[key].id] = data.list[key].name
                    if (this.companyProjectsMap[data.list[key].companyId] == null) {
                        this.companyProjectsMap[data.list[key].companyId] = [{
                            value: "",
                            label: "全部项目"
                        }]
                    }

                    this.companyProjectsMap[data.list[key].companyId].push({
                        value: data.list[key].id,
                        label: data.list[key].name
                    })
                }
                this.projectSelectOptions = proOptions
                this.projectOptions = proOptions
                this.orgProjectOptions = proOptions
                // 再刷新表格数据

                this.refreshMaterialInfo()
            }).catch((error) => {
                this.loading = false
                console.log(error)
            })
        },
        refreshMaterialInfo() {
            console.log('query material')
            this.$store.dispatch('material/queryMaterialInfoByCode', {
                materialCode: this.currMaterialCode
            }).then((data) => {
                this.loading = false
  
                this.editingMaterialId = data.id
                this.projectSelectOptions = this.companyProjectsMap[data.companyId] != null ? this.companyProjectsMap[data.companyId] : [{  value: "",  label: "全部项目" }]
                this.materialform.company = data.companyId
                this.materialform.type = data.type
                this.materialform.materialCode= this.currMaterialCode
                this.materialform.createdTime = data.createdTime
                this.materialform.bindTime = data.bindTime
                this.materialform.lifecyclePhase = data.lifecyclePhase
                this.materialform.projectId = data.projectId
                this.materialform.description = data.description
            }).catch((e) => {
                this.loading = false
            })
        },
        updateMaterialById() {
            console.log('update material')
            this.$store.dispatch('material/updatematerial', {
                materialId: this.editingMaterialId,
                companyId: this.materialform.company,
                planeId: this.materialform.plane,
                project: this.materialform.projectId,
                lifecyclePhase: this.materialform.lifecyclePhase,
                type: this.materialform.type,
                description: this.materialform.description
            }).then(() => {
                this.showEdit = false
                this.hideEdit = true
                this.loading = false
                ElMessage.success('绑定成功!')
                this.linkbacktopage()
            }).catch((e) => {
                ElMessage.error('绑定失败!')
                this.loading = false
            })
        },
        linkbacktopage() {
            if (window.location.href.includes("recordeditmaterial")) {
                this.$router.push({ path: '/materialrecord',
                    query: {
                        materialCode: this.currMaterialCode
                    }
                })
            } else {
                this.$router.push({ path: '/material',
                    query: {
                        
                    }
                })
            }
        },
        initUIConfig() {
            this.uiConfig = {
                isEditBtnShow: storage.getDataCache("userRights").includes("role.gms.air.device.edit")
            }
        },
        initPage() {
            var browerWidth = window.innerWidth; //浏览器可视宽度
            var browerHeight = window.innerHeight; //浏览器可视宽度
            if (browerHeight < 739) {
                browerHeight = 739
            }
        }
    },
    mounted: function() {
        console.log('page device')
        this.currentPage = 1
        this.pageSize = 10;
        this.totalNum = 0;
        this.initCompanyOptions()
        this.currMaterialCode = this.$route.query.materialCode
        this.initPage()
        this.initUIConfig()
        window.addEventListener('resize', this.initPage)
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.initPage)
    },
    components: {
        CaretBottom
    } 
}; 
</script>

<style scoped>
.el-button+.el-button {
    margin-left: 25px;
}
.pager-content{
    float: right;
}
 
.el-form-item {
    .el-input {
    width: 320px;
    }
    .el-select{
    width: 320px;
    }
}
.float_right {
    float: right;
}

.option {
    margin-left: 10px;
}

.el-table .el-table__header th {
    background-color: #F4F7FA !important;
    border-right: 0 none；
}

.form-footer {
    float: right;
    justify-items: end;
    margin-top: 20px;
}

.el-date-editor{
    width: -webkit-fill-available;
}
</style> 

<style lang="scss">
.notrequired .el-form-item__label {
    margin-left: 0px;
}

.search-icon-hover {
    cursor: pointer;
}
</style>

<style>
.el-dropdown-link:focus {
    outline: none;   /* 去除，悬浮在el-dropdown时，黑色边框, */
}

.editdatepicker{
    .el-icon {
        display: none;
    }
}
</style>