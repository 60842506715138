import { getTaskInfo, getTaskLineInfo, getTaskFaultInfo, getTaskTracksList } from '@/api/flightTask'
import { getToken, getUserInfo } from '@/utils/auth'
import { resetRouter } from '@/router'

const getDefaultState = () => {
  return {
    token: getToken(),
    userInfo: getUserInfo(),
    name: '',
    avatar: ''
  }
}

const state = getDefaultState()

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState())
  }
}

const actions = {
  // user login
  queryTaskInfo({ commit }, pageParam) {
    const { taskCode, sn  } = pageParam
    return new Promise((resolve, reject) => {
        getTaskInfo({ taskCode: taskCode, sn: sn }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  queryTaskLineInfo({ commit }, pageParam) {
    const { taskLineId  } = pageParam
    return new Promise((resolve, reject) => {
        getTaskLineInfo({ taskLineId: taskLineId }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  queryTaskFaultInfo({ commit }, pageParam) {
    const { taskCode  } = pageParam
    return new Promise((resolve, reject) => {
        getTaskFaultInfo({ taskCode: taskCode }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  queryTaskTracksList({ commit }, pageParam) {
    const { taskCode, sn  } = pageParam
    return new Promise((resolve, reject) => {
      getTaskTracksList({ taskCode: taskCode, sn: sn }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

