import request from '@/utils/request'
 
export function addPlane(data) {
  return request({
    url: '/bd-gms/device/plane',
    method: 'post',
    data
  })
}

export function getPlaneInfo(data) {
  return request({
    url: "/bd-gms/device/plane/info" + "?planeId=" + data.planeId,
    method: 'get'
  })
}

export function getPlaneList(data) {
  return request({
    url: '/bd-gms/device/plane/list',
    method: 'post',
    data
  })
}

export function deletePlane(data) {
    return request({
      url: '/bd-gms/device/plane' + "?planeId=" + data.planeId,
      method: 'delete'
    })
  }

  export function updatePlane(data) {
    return request({
      url: '/bd-gms/device/plane/info',
      method: 'post',
      data
    })
  }