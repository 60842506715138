import request from '@/utils/request'

export function getFaultCodeList(data) {
  return request({
    url: '/bd-gms/faultcode/list',
    method: 'post',
    data
  })
}

export function getFaultChargePersonList(data, yunxiaoProjectId) {
  return request({
    url: '/bd-gms/faultcode/setting/chargepersons?yunxiaoProjectId=' + yunxiaoProjectId,
    method: 'post',
    data
  })
}

export function getYunxiaoProjectList(data) {
  return request({
    url: '/bd-gms/faultcode/setting/yunxiaoprojects',
    method: 'post',
    data
  })
}

export function updateFaultSetting(data) {
  return request({
      url: '/bd-gms/faultcode/info',
      method: 'post',
      data
  })
}

export function addFaultCodeSetting(data) {
  return request({
      url: '/bd-gms/faultcode/setting',
      method: 'post',
      data
  })
}

export function deleteFaultSetting(data) {
  return request({
      url: '/bd-gms/faultcode/setting' + '?id=' + data.id,
      method: 'delete',
      data
  })
}


export function downloadFaultSetting(data, onDownloadProgress) {
  return request({
    url: '/bd-gms/faultcode/setting/export' + '?projectId=' + data.project,
    method: 'post',
    responseType: 'blob',
    data,
    onDownloadProgress: onDownloadProgress
  })
}